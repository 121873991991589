const statSpecificData = [
	{
		title: 'General Statistics',
		content: [
			{
				title: '19.7 million',
				desc: `According to the National Survey on Drug Use and Health (NSDUH), alcohol and drug use disorders simultaneously.`
			},
			{
				title: '8.5 million',
				desc: `In 2017, 8.5 million American adults suffered from both a mental health disorder and a substance use disorder, or co-occurring disorders (i.e., dual diagnosis).`
			},
			{
				title: '34%',
				desc: `Too often, those living with a dual diagnosis receive treatment for only one of their ailments. It is estimated that of the adults living with co-occurring disorders, 34 percent receive mental health treatment, 2 percent enroll in drug rehab, and 12 percent get the help they need for both disorders..`
			},
			{
				title: '$740 billion',
				desc: `Drug abuse and addiction cost American society more than $740 billion annually in lost workplace productivity, healthcare expenses, and crime-related costs.`
			},
			{
				title: '20.7 million',
				desc: `In 2017, an estimated 20.7 million people age 12 and older needed treatment for a substance use disorder. Only 4 million people received treatment, or about 19% of those who needed it.`
			},
			{
				title: '18 million',
				desc: `In 2017, of the more than 18 million people who needed but did not receive treatment for substance use, only 1 million, or 5.7%, of those people felt they needed treatment.`
			},
			{
				title: '120,000 groups',
				desc: `Alcoholics Anonymous (AA) has more than 120,000 groups in more than 175 countries around the world, with more than 2 million members.`
			},
			{
				title: '14,500',
				desc: `There are over 14,500 specialized substance abuse treatment facilities in the United States providing a variety of care options, including counseling, behavioral therapy, medication, case management, and other forms of care.`
			},
			{
				title: '40% - 60%',
				desc: `The relapse rate for substance use disorders is estimated to be between 40% and 60%. This rate is similar to rates of relapse for other chronic diseases such as hypertension or asthma.`
			},
			{
				title: '10%',
				desc: `Addiction is considered a highly treatable disease, and recovery is attainable. About 10% of American adults who are at least 18 years old say they are in recovery from an alcohol or drug abuse issue.`
			}
		]
	},
	{
		title: 'Adolescents (aged 12&#x2012;17)',
		content: [
			{
				title: '1 in 25',
				desc: `In 2017, approximately 4% of the American adolescent population age 12 to 17 suffered from a substance use disorder; this equals 992,000 teens or 1 in 25 people in this age group.`
			},
			{
				title: '443,000',
				desc: `About 443,000 adolescents age 12 to 17 had an alcohol use disorder in 2017, or 1.8% of adolescents.`
			},
			{
				title: '3%',
				desc: `An estimated 741,000 adolescents suffered from an illicit drug use disorder in 2017, or about 3% of this population.`
			}
		]
	},
	{
		title: 'Young adults (aged 18&#x2012;25)',
		content: [
			{
				title: '5.1 million',
				desc: `About 5.1 million young adults age 18 to 25 battled a substance use disorder in 2017, which equates to 14.8% of this population and about 1 in 7 people.`
			},
			{
				title: '10%',
				desc: `About 3.4 million young adults age 18 to 25 had an alcohol use disorder in 2017, or about 10% of young adults.`
			},
			{
				title: '7.3%',
				desc: `About 2.5 million young adults had an illicit drug use disorder in 2017, or about 7.3% of this population.`
			},
			{
				title: '200%',
				desc: `Heroin use among young adults between 18 and 25 years old doubled in the past decade.`
			}
		]
	},
	{
		title: 'Over age 26',
		content: [
			{
				title: '13.6 million',
				desc: `Approximately 13.6 million adults age 26 or older struggled with a substance use disorder in 2017, or 6.4% of this age group.`
			},
			{
				title: '5%',
				desc: `About 10.6 million adults age 26 and older had an alcohol use disorder in 2017, or about 5% of this age group.`
			},
			{
				title: '4.3 million',
				desc: `About 4.3 million adults age 26 or older had an illicit drug use disorder in 2017, or 2% of this age group.`
			}
		]
	},
	{
		title: 'Men vs. women',
		content: [
			{
				title: '9.4% vs 5.2%',
				desc: `In 2017, about 9.4% of men and 5.2% of women age 12 and older had a substance use disorder.`
			},
			{
				title: 'Men are 3x likely',
				desc: `Adult men are 2 to 3 times more likely than women to abuse illicit drugs than women, but women may be just as prone to addiction as men when they do abuse them.`
			}
		]
	},
	{
		title: 'Prescription drugs',
		content: [
			{
				title: 'Most Common',
				desc: `The most common types of prescription drugs abused in 2017 were pain relievers, tranquilizers, stimulants, and sedatives.`
			},
			{
				title: '1.7 million',
				desc: `In 2017, about 1.7 million people age 12 and older had a pain reliever use disorder, or about 0.6% of this population.`
			},
			{
				title: 'Dependency',
				desc: `Women may more rapidly develop a prescription painkiller dependence than men. They are also more likely to have chronic pain, be prescribed pain relievers, and receive higher doses.`
			},
			{
				title: 'Opioid Treatment',
				desc: `According to a study published in the journal Substance Abuse Treatment, Prevention, and Policy, individuals who were admitted to opioid treatment programs who abused only prescription opioids, or those who abused both heroin and prescription opioids, were about 5 years younger than individuals admitted solely for heroin abuse or dependency.`
			}
		]
	},
	{
		title: 'Heroin',
		content: [
			{
				title: '652,000',
				desc: `About 652,000 people age 12 and older had a heroin use disorder in 2017.`
			},
			{
				title: '1/4',
				desc: `Almost a quarter of people who abuse heroin will become addicted to it.`
			},
			{
				title: 'Past 2 Decades',
				desc: `Heroin use has risen in most demographic groups in the United States over the past 2 decades, the Centers for Disease Control and Prevention (CDC) reports.`
			},
			{
				title: '40x',
				desc: `Individuals addicted to alcohol are 2 times more likely to also be addicted to heroin, while those addicted to marijuana are 3 times more likely, individuals addicted to cocaine are 15 times more likely, and people addicted to prescription drugs are 40 times more likely.`
			}
		]
	},
	{
		title: 'Cocaine',
		content: [
			{
				title: '966,000',
				desc: `About 966,000 American adults (over age 12) struggled with a cocaine use disorder in 2017.`
			},
			{
				title: '637,000',
				desc: `That same year, 637,000 people age 12 and older received treatment for a cocaine use disorder either in their last or current stay in rehabilitation.`
			}
		]
	},
	{
		title: 'Alcohol',
		content: [
			{
				title: '14.5 million',
				desc: `In 2017, an estimated 14.5 million American adults age 12 and older battled an alcohol use disorder, or 5.3% of this population.`
			},
			{
				title: 'Over 50%',
				desc: `Over half of all American adults have a family history of problem drinking or alcohol addiction.`
			},
			{
				title: 'Over 10%',
				desc: `More than 10% of U.S. children live with a parent with alcohol problems.`
			},
			{
				title: '88,000',
				desc: `An estimated 88,000 people die from alcohol-related causes annually.`
			},
			{
				title: 'Alchohol & Death',
				desc: `Alcohol is the third-leading cause of preventable death in the United States.`
			},
			{
				title: '78,529',
				desc: `47% of the 78,529 liver diseases among people age 12 and older in 2015 involved alcohol.`
			},
			{
				title: '40%',
				desc: `40% of all hospital beds in the United States are used to treat conditions related to alcohol consumption.`
			}
		]
	},
	{
		title: 'Marijuana',
		content: [
			{
				title: '4.1 million',
				desc: `Approximately 4.1 million American adults over the age of 12 battled a marijuana use disorder in 2017.`
			},
			{
				title: 'Ages 12 - 25',
				desc: `The majority of people struggling with marijuana addiction in 2017 were between the ages of 12 and 25.`
			},
			{
				title: '6%',
				desc: `Almost 6% of full-time college students in the United States were daily marijuana smokers in 2014. This is more than triple the number of daily smokers in this population 20 years prior.`
			}
		]
	}
];

const statsOtherData = [
	{
		title: 'Eating disorders',
		text:
			'Eating disorders are a serious and often fatal illnesses that are associated with severe disturbances in people’s eating behaviors and related thoughts and emotions. Preoccupation with food, body weight, and shape may also signal an eating disorder. Common eating disorders include: anorexia nervosa, bulimia nervosa, binge-eating disorder, avoidant restrictive food intake disorder (ARFID), and other specified feeding or eating disorders (OSFED).',
		content: [
			{
				title: '35-40%',
				desc: `Relapse rate of eating disorders is 35-40% within 18 months.`
			},
			{
				title: 'Over 90%',
				desc: `Over 90% of individuals struggling with an eating disorder have a co-occurring mood disorder (e.g., depression, PTSD, OCD).`
			},
			{
				title: '1 out of 19',
				desc: `In one study of ultra-Orthodox and Syrian Jewish communities in Brooklyn, 1 out of 19 girls was diagnosed with an eating disorder, which is a rate about 50 percent higher than the general U.S. population. (Sacker, 1996).`
			},
			{
				title: '40-60%',
				desc:
					'By age 6, girls especially start to express concerns about their own weight or shape. 40-60% of elementary school girls (ages 6-12) are concerned about their weight or about becoming too fat. This concern endures through life.'
			},
			{
				title: '12.6%',
				desc:
					'12.6% of female high school students take diet pills, powders or liquids to control their weight without a doctor’s advice.'
			},
			{
				title: 'Eating Disorders',
				desc:
					'Eating disorders are associated with some of the highest levels of medical and social disability of any psychiatric disorder.'
			},
			{
				title: 'High Mortality',
				desc:
					'Eating disorders have the second highest mortality rate of all mental health disorders, surpassed only by opioid addiction.'
			},
			{
				title: '9 out of 10',
				desc: `9 out of 10 women say they will actually not eat and risk putting their health at stake when they feel bad about their body image, and 7 in 10 girls said they're more likely to be less assertive in their decisions when they're feeling insecure.`
			},
			{
				title: '50%',
				desc:
					'According to the National Center on Addiction and Substance Abuse, up to 50% of individuals with eating disorders abused alcohol or illicit drugs, a rate five times higher than the general population.'
			},
			{
				title: 'Suicide Risk',
				desc:
					'Eating disorders and substance abuse share a number of common risk factors, including brain chemistry, family history, low self-esteem, depression, anxiety, and social pressures. Other shared characteristics include compulsive behavior, social isolation, and risk for suicide.'
			},
			{
				title: 'ED and Alcohol',
				desc:
					'25% of people with anorexia, 34% of people with bulimia, and 21% of people with binge eating disorder abuse or are dependent on alcohol.'
			},
			{
				title: 'ED and Drugs',
				desc:
					'27% of people with anorexia, 37% of people with bulimia, and 23% of people with binge eating disorder abuse or are dependent on other substances.'
			},
			{
				title: 'ED and PTSD',
				desc:
					'Individuals with bulimia nervosa, binge eating disorder, or any binge eating have significantly higher rates of PTSD than individuals without an eating disorder. Two major national representative studies found high rates of lifetime PTSD—38% and 44%—in those with bulimia nervosa.'
			}
		]
	},
	{
		title: 'Post&#x2012;traumatic stress disorder (PTSD)',
		text: `Post-traumatic stress disorder (PTSD) can occur after you have been through a trauma. A trauma is a shocking and dangerous event that you see or that happens to you. During this type of event, you think that your life or others' lives are in danger.`,
		content: [
			{
				title: '10 of 100',
				desc: `About 10 of every 100 women (or 10%) develop PTSD sometime in their lives compared with about 4 of every 100 men (or 4%).`
			},
			{
				title: '7-8%',
				desc: `About 7 or 8 out of every 100 people (or 7-8% of the population) will have PTSD at some point in their lives.`
			}
		]
	},
	{
		title: 'Depression (Major Depressive Disorder)',
		text: `Depression (Major Depressive Disorder) is a common and serious medical illness that negatively affects how you feel, the way you think and how you act. Fortunately, it is also treatable. Depression causes feelings of sadness and/or a loss of interest in activities once enjoyed. It can lead to a variety of emotional and physical problems and can decrease a person’s ability to function at work and at home.`,
		content: [
			{
				title: '12 million',
				desc: `Approximately 12 million women in the United States experience clinical depression each year.`
			},
			{
				title: 'Twice the Rate',
				desc: `Women experience depression at roughly twice the rate of men.`
			},
			{
				title: 'Suicide',
				desc:
					'Although men are more likely than women to die by suicide, women report attempting suicide approximately twice as often as men.'
			}
		]
	}
];

export { statSpecificData, statsOtherData };
