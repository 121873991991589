import React from 'react';

import './getHelp.scss';

const GetHelp = props => {
	const content = [
		{
			title: `I use alcohol/drugs and destructive behaviors to deaden the pain and chaos in my life`,
			content: `Congratulations, you have taken the first step and admitted that your life is unmanageable. We are here to help.`,
			buttonText: 'Get Help Now'
		},
		{
			title: `My loved one is numbing herself with out-of-control alcohol/drug abuse and destructive behaviors`,
			content: `We feel your pain and are here to help.`,
			buttonText: 'Get in Touch'
		}
	];
	return (
		<div className='get-help default-width'>
			{props.showTitle && (
				<div className='title'>
					<h3>Recovery Is Possible</h3>
					<h4>Get the Help you Need</h4>
				</div>
			)}
			<div className='recovery-options'>
				<Recovery {...content[0]} />
				<div className='middle-or'>
					<h3>or</h3>
				</div>
				<Recovery {...content[1]} />
			</div>
		</div>
	);
};

export default GetHelp;

const Recovery = props => {
	return (
		<a href='/contact' className='recovery'>
			<h4 className='heading'>{props.title}</h4>
			<div className='line'></div>
			<div className='content'>{props.content}</div>

			<div className='button'>{props.buttonText}</div>
		</a>
	);
};
