const ContentText = {
	about: [
		{
			title: 'About Beit Nesicha',
			content: [
				'Beit Nesicha is a dual diagnosis treatment program that aims to restore confidence and self-worth to young Jewish women (ages 17 and up) who are struggling with addiction and co-occurring mental health issues (trauma-related, anxiety/panic, depression, body-image/eating disorders). We provide a warm, empowering environment for these women seeking to nurture their royal spark into a shining beacon of serenity, dignity, and courage. Our setting promotes growth from the inside out, where one’s past has no bearing on one’s future, and where honesty and self-respect reign.',
				'Our program is located in Kochav Yaakov - a warm community in the rural outskirts of Jerusalem. Our location outside of the city helps to avoid the noise, rush, and attractions - all distractions from recovery. The quiet, open environment promotes personal growth, anxiety/stress reduction, self-exploration, and clarity.',
				'As a result of working through the Beit Neischa program, Jewish women will recover from addiction and co-occurring mental health issues, and start living their lives according to their hopes and dreams. Graduates will maintain sobriety and use healthy coping methods to help navigate through life. They will have the self-worth to not hurt themselves anymore (both physically and emotionally), establish and maintain relationships with healthy boundaries, feel comfortable in their own skin, and recognize the importance of their presence in the world.'
			]
		},
		{
			imgPath: 'https://via.placeholder.com/900x300'
		},
		{
			title: 'How Beit Nesicha is similar to other programs:',
			content: [
				'We use treatment planning, evidence-based interventions (e.g., the 12-step model, ACT), and mind/body activities (e.g., fitness, meditation), to help promote recovery and minimize delinquent/high-risk behaviors. We strive to rebuild family relationships, towards developing a healthy support network that aids in relapse prevention. Treatment extends beyond therapy to include developing independent living skills and effective communication skills, through workshops and achieving educational/vocational goals (e.g., earning a GED).'
			]
		},
		{
			title: 'How Beit Nesicha is unique:',
			content: [
				`We consider low self-worth to be the key factor in addiction/relapse and mental health issues. The individual constantly struggles with an inner critic that relays the message, "You're not good enough, you don't deserve to have a good life, you don't deserve to feel loved." The constant presence of this inner critic explains why individuals graduate treatment programs, get a good job and build relationships with positive individuals, and ultimately self-sabotage their successes. Deep down inside, the inner critic gives the constant message, "you don't deserve this." Our program was built with the primary focus of weakening this inner critic. We use the ACA 12-step program (have a link to the ACA page), group activities, spiritual growth, and therapies to help identify and become mindful of the inner critic, in order to challenge and overcome its negative pull towards self-sabotage.`,
				`We recognize that childhood trauma and lack of support create the gateway to addiction and co-occurring mental health issues. Our residential program focuses on processing and reducing the effects of trauma in a group setting. Moving into Transitional Living, the individual is now stable (e.g., withdrawal symptoms no longer present), and has the tools to effectively engage in (and get the most out of) individual therapy per treatment needs.`,
				`Moving beyond the basic goal of maintaining sobriety, Beit Nesicha measures progress through bi-weekly mental health testing/assessments, treatment plan review, and individual/family feedback. We are 100% invested in the success of every woman who enters our program. As part of the Beit Nesicha family, we continue to stay in touch with our graduates, and look forward to sharing and being supportive in all of their future occasions (both joyous and sad).`,
				`Beit Nesicha is not a penal or boot-camp system, but instead a warm, family-oriented program that promotes growth on both an individual and group level. We believe that individuals in recovery are often too hard on themselves (especially when they set unrealistic goals and fall short). Our structured daily schedule, interventions, warm and open atmosphere, and staff modeling help women in our program to excel in: learning independent living skills, becoming assertive for their needs/wants, and developing healthy coping skills for daily stressors and regulating emotions. As their self-worth builds, resentments towards themselves and others will fall away, they will start taking ownership of their lives, and they will set healthy achievable life goals that promote happiness, love, connection, and a sense of belonging.`,
				`Beit Nesicha uses Torah concepts to modify current treatment models. Our program uses 40-day increments instead of the current 30-day increments seen in other programs. Understandably, current programs use 30-day increments to align with monthly cycles, and for insurance purposes. The Torah views 40 days as a block of time for creation or significant change. Several examples: the Torah was given after 40 days, the neshama enters the fetus after 40 days from conception, it takes 40 days to change a middah (characteristic/virtue). The residential program at Beit Nesicha is divided into 3 x 40-day tiers. A fitness trainer once stated that he advised people who were seeking to diet, to start with introducing healthy foods, and specifically not to stop eating unhealthy foods as this would create an immediate desire for those foods. He believed that psychologically, we crave things that are unhealthy for us and the craving greatly increases when we think we can no longer have them. Therefore, a person should keep eating the unhealthy food and introduce different greens (e.g., kale, spinach, salads, etc.). After a while, the body will naturally want the healthy foods and slowly move away from the unhealthy foods. Similarly, a historic ideal within the Mussar movement has been to build (strengthen) the person before giving mussar. The Beit Nesicha residential program follows this ideal - first we build. The 1st tier focuses on introducing positive thoughts and behaviors (connecting within the group, spiritual growth, opening up in a safe and welcoming setting). The 2nd tier focuses on reducing negative thoughts and behaviors (reducing mistrust, dishonesty, self-harm). The 3rd tier focuses on stabilizing - maintaining the growth from the previous tiers, and refining individual treatment goals.`,
				`Our program is founded on Torah principles. Therefore, we offer Judaism - educational classes, kosher food, and Shabbat/Holidays are honored with connection, good food, and song. To clarify, we offer Judaism, but it is not forced. This means no one is obligated to keep Jewish law. However, we ask that there is mutual respect in keeping the kitchen kosher, and wearing clothes with self-respect in mind (pants are allowed - self respect means not wearing something too revealing or distasteful).`,
				`Spiritual growth and connection: Every weekday morning there will be a trip to Mishkan Shilo where women will have time to meditate and pray, followed by a process group. Beit Nesicha views Mishkan Shilo as a very special place for women as it is a Torah-documented source where a Jewish woman connected and had her prayers answered - Chana prayed to have a child at Mishkan Shilo and she gave birth to the prophet Shmuel. Beit Neischa makes Rosh Chodesh a very special time for women through having a festive breakfast and visiting the Kotel. We also advise families to buy something nice before holidays for their loved ones in treatment.`,
				`Throughout the entire program, women will be given their phones (or access using program computers) for a few hours at the end of the clinical day. We want them to have the option to reconnect with family, strengthen their support network, and enjoy personal time on social media/internet - within a healthy time-frame.`
			]
		},
		{
			title: 'Requirements:',
			content: [
				`While we do not have a penal system, there are 3 reasons for being asked to leave our program:`,
				`1. Putting your life at risk. For example, overdosing, continued heavy drug use, or getting into high-risk/life-threatening situations. To clarify, this is a completely sober program. This means alcohol and all illicit drugs (e.g., marijuana, psychedelics, opiates, amphetamines) are not allowed. We also do not allow addictive prescription drugs (e.g., Adderall). At Beit Nesicha we do not believe in expelling someone from our program for slipping, but instead we intervene and work with the individual toward successfully completing our program.`,
				`2. Putting someone else's life at risk. For example, bringing drugs or alcohol into the facility.`,
				`3. Putting the program at risk. For example, the program is located in a shomer-Shabbat community. While women are allowed to smoke on Shabbat (Judaism is offered in our program, not forced), they have a designated area for smoking. If someone is seen smoking outside of the facility on Shabbat, and community members complain, this puts our program at risk.`
			]
		},
		{
			title: `Who is not a good candidate:`,
			content: [
				`Someone who requires a higher level of care (i.e., hospitalization) due to medical or psychiatric needs.`,
				`Someone with a high-risk eating disorder that requires: ECG, continuous weight/nutrition monitoring`,
				`Someone who is not really interested in treatment and feels "coerced" - we understand that it is common to struggle with hope in having a better life, and that treatment works. We welcome individuals who are hesitant and skeptical of treatment, but there must be some spark of hope or yearning to change their lives.`
			]
		}
	],
	definitions: [
		{
			title: 'Dual diagnosis',
			content: [
				'(Also referred to as co-occurring disorders) is a term for when someone experiences a mental health issue and a substance use disorder simultaneously.'
			]
		},
		{
			title: 'Addiction',
			content: [
				`Compulsive (loss of control) reward seeking at the expense of relationships, obligations, and physical/emotional well being. An individual can be addicted to: alcohol, a drug/substance (e.g., heroin, cocaine, marijuana), shopping, work, exercise, eating, gambling, debting, sex, pornography, gaming, and social media. Individuals engage in addictive behaviors to escape from their painful realities and emotions. Individuals who struggle with addiction tend to be extremely sensitive to injustice, and lack healthy coping skills to work through daily stressors. The addiction helps to temporarily numb feelings, which can include anxiety, sadness, anger, and even joy. Yes, addicts don't want to feel anything - even positive feelings/emotions.`,
				`Addiction defined by the American Society of Addiction Medicine: Addiction is a primary, chronic disease of brain reward, motivation, memory and related circuitry. Dysfunction in these circuits leads to characteristic biological, psychological, social and spiritual manifestations. This is reflected in an individual pathologically pursuing reward and/or relief by substance use and other behaviors. Addiction is characterized by inability to consistently abstain, impairment in behavioral control, craving, diminished recognition of significant problems with one’s behaviors and interpersonal relationships, and a dysfunctional emotional response. Like other chronic diseases, addiction often involves cycles of relapse and remission. Without treatment or engagement in recovery activities, addiction is progressive and can result in disability or premature death.`,
				`Addiction is known as "Alcohol/Substance" Use Disorder in the DSM 5. The severity (mild, moderate, severe) is rated according to the number of reported criteria (American Psychiatric Association, 2013):`,
				`1. Taking the substance in larger amounts or for longer than you're meant to.`,
				`2. Wanting to cut down or stop using the substance but not managing to.`,
				`3. Spending a lot of time getting, using, or recovering from use of the substance.`,
				`4. Cravings and urges to use the substance.`,
				`5. Not managing to do what you should at work, home, or school because of substance use.`,
				`6. Continuing to use, even when it causes problems in relationships.`,
				`7. Giving up important social, occupational, or recreational activities because of substance use.`,
				`8. Using substances again and again, even when it puts you in danger.`,
				`9. Continuing to use, even when you know you have a physical or psychological problem that could have been caused or made worse by the substance.`,
				`10. Needing more of the substance to get the effect you want (tolerance).`,
				`11. Development of withdrawal symptoms, which can be relieved by taking more of the substance.`
			]
		},
		{
			imgPath: 'https://via.placeholder.com/900x300'
		},
		{
			title: 'Signs of Addictions',
			content: [
				'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur.',
				'Sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
				'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.'
			]
		},
		{
			title: 'Addiction Statistics',
			content: [
				'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur.',
				'Invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.',
				'Consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit ametundefined'
			]
		}
	],

	treatment: [
		{
			title: 'Treatment Therapies',
			content: [
				'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.At vero eos et accusam et justo duo dolores et ea rebum.Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consetetur.',
				'Sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.At vero eos et accusam et justo duo dolores et ea rebum.Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
				'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.At vero eos et accusam et justo duo dolores et ea rebum.Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet.',
				'Consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.At vero eos et accusam et justo duo dolores et ea rebum.Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit ametundefined'
			]
		},
		{
			imgPath: 'https://via.placeholder.com/900x300'
		},
		{
			title: 'Treatment Process',
			content: [
				'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur.',
				'Sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.'
			]
		},
		{
			title: 'Detox',
			content: [
				'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur.',
				'Invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.',
				'Consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit ametundefined'
			]
		},
		{
			title: 'Inpatient Treatement',
			content: [
				'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur.',
				'Sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
				'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.'
			]
		},
		{
			imgPath: 'https://via.placeholder.com/900x300'
		},
		{
			title: 'Outpatien Treatementt',
			content: [
				'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur.',
				'Invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.',
				'Consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit ametundefined'
			]
		}
	],

	recovery: [
		{
			title: 'Preventing Relapse',
			content: [
				'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.At vero eos et accusam et justo duo dolores et ea rebum.Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet, consetetur.',
				'Sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.At vero eos et accusam et justo duo dolores et ea rebum.Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.',
				'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.At vero eos et accusam et justo duo dolores et ea rebum.Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.Lorem ipsum dolor sit amet.',
				'Consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.At vero eos et accusam et justo duo dolores et ea rebum.Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit ametundefined'
			]
		},
		{
			imgPath: 'https://via.placeholder.com/900x300'
		},
		{
			title: 'Living in Recovery',
			content: [
				'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur.',
				'Sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.'
			]
		}
	]
};

export default ContentText;
