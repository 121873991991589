import React from 'react';
import { useParams } from 'react-router-dom';
import StatsComponent from '../reusable/StatsComponent';
import { statSpecificData, statsOtherData } from '../../content/stats';

import MetaTags from 'react-meta-tags';

import './sass/statsIndividualPage.scss';

const StatsIndividualPage = () => {
	// const { title } = useParams();

	let title = window.location.href.split('/');
	title = title[title.length - 1];

	let statContent = [...statSpecificData, ...statsOtherData].filter(stat => {
		return (
			stat.title
				.toLocaleLowerCase()
				.split(' ')
				.join('-') === title
		);
	});

	statContent = statContent[0];

	return (
		<div className='stats-individual-page default-width'>
			<MetaTags>
				<title>Statistics</title>
				<meta
					name='description'
					content='On average, 6-10 addiction cases and 6-10 mental illness cases are opened weekly.'
				/>
				<meta property='og:title' content='Statistics' />
			</MetaTags>

			<div className='bg-logo'></div>
			<h2 className='main-title'>Statistics for</h2>
			<div className='diff-title'>
				<h3
					className='title'
					dangerouslySetInnerHTML={{ __html: statContent.title }}
				></h3>
			</div>
			<p className='paragraph-text'>{statContent.text}</p>
			<div className='stats-container'>
				{statContent.content.map(content => {
					return (
						<div className='stat' key={content.title}>
							<h4 className='stat-title'>{content.title}</h4>
							<div className='line'></div>
							<div className='stat-desc'>{content.desc}</div>
						</div>
					);
				})}
			</div>
			<StatsComponent title='Other Statistics' />
		</div>
	);
};

export default StatsIndividualPage;
