import React from 'react';
import MetaTags from 'react-meta-tags';
import './sass/statsPage.scss';

const StatsPage = () => {
	return (
		<div className='stats-page default-width'>
			<MetaTags>
				<title>Stats and Definitions</title>
				<meta
					name='description'
					content='On average, 6-10 addiction cases and 6-10 mental illness cases are opened weekly.'
				/>
				<meta property='og:title' content='Stats and Definitions' />
			</MetaTags>

			<div className='amudim-stats'>
				<div className='bg-logo'></div>
				<div className='diff-title'>
					<h3 className='title'>Amudim Statistics</h3>
				</div>

				<p>
					Amudim provides a weekly newsletter with the number of cases
					opened per week. On average,{' '}
					<span>6-10 addiction cases</span> and{' '}
					<span>6-10 mental illness cases are opened weekly</span>.
					These are Jews seeking help. The numbers increase when we
					factor in how many are seeking help from other places. The
					numbers painfully increase when we factor in how many need
					help but are not actively seeking it. We cannot deny that
					our communities are in desperate need of support, education,
					and interventions to help individuals struggling with
					addiction and mental health issues. Losing one of our family
					members to overdose, suicide, or mental illness is already
					too much.
				</p>
			</div>

			<div className='stats-overall-container'>
				{statSpecificData.map((stat, index) => {
					let containerClass = 'stats-container';
					if (stat.content.length < 3) containerClass += ' center';

					return (
						<div key={stat.title}>
							<div className='diff-title'>
								<h3 className='title'>{stat.title}</h3>
							</div>

							<div className={containerClass}>
								{index === 1 && (
									<div className='bg-logo right first'></div>
								)}

								{index === 3 && <div className='bg-logo'></div>}

								{index === 7 && (
									<div className='bg-logo right third'></div>
								)}

								{index === 9 && <div className='bg-logo'></div>}

								{stat.content.map(content => {
									return (
										<div
											className='stat'
											key={content.title}
										>
											<h4 className='stat-title'>
												{content.title}
											</h4>
											<div className='line'></div>
											<div
												className='stat-desc'
												dangerouslySetInnerHTML={{
													__html: content.desc
												}}
											></div>
										</div>
									);
								})}
							</div>
						</div>
					);
				})}
			</div>

			<div className='reference-container'>
				<h4>References</h4>
				<ul>
					<li>
						<span></span>
						<p>
							Substance Abuse and Mental Health Services
							Administration. (2018).{' '}
							<a
								target='_blank'
								rel='noopener noreferrer'
								href='https://www.samhsa.gov/data/report/2017-nsduh-annual-national-report'
							>
								Key Substance Use and Mental Health Indicators
								in the United States: Results from the 2017
								National Survey on Drug Use and Health
							</a>
							.
						</p>
					</li>
					<li>
						<span></span>
						<p>
							Alcoholics Anonymous. (2018).{' '}
							<a
								targe='_blank'
								rel='noopener noreferrer'
								href='https://www.aa.org/assets/en_US/smf-132_en.pdf'
							>
								Estimated Worldwide A.A. Individual and Group
								Membership
							</a>
							.
						</p>
					</li>
					<li>
						<span></span>
						<p>
							National Institute on Drug Abuse. (2018).{' '}
							<a
								targe='_blank'
								rel='noopener noreferrer'
								href='https://www.drugabuse.gov/publications/principles-drug-addiction-treatment-research-based-guide-third-edition/drug-addiction-treatment-in-united-states'
							>
								Drug Addiction Treatment in the United States
							</a>
							.
						</p>
					</li>
					<li>
						<span></span>
						<p>
							Centers for Disease Control and Prevention. (2015).{' '}
							<a
								targe='_blank'
								rel='noopener noreferrer'
								href='https://www.cdc.gov/vitalsigns/heroin/index.html'
							>
								Today’s Heroin Epidemic
							</a>
							.
						</p>
					</li>
					<li>
						<span></span>
						<p>
							American Society of Addiction Medicine. (2016).{' '}
							<a
								targe='_blank'
								rel='noopener noreferrer'
								href='https://www.asam.org/docs/default-source/advocacy/opioid-addiction-disease-facts-figures.pdf'
							>
								Opioid Addiction Facts &amp; Figures
							</a>
							.
						</p>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default StatsPage;

const statSpecificData = [
	{
		title: 'General Statistics',
		content: [
			{
				title: '19.7 million',
				desc: `According to the National Survey on Drug Use and Health (NSDUH), <a target="_blank" rel="noopener noreferrer" href="https://www.samhsa.gov/data/report/2017-nsduh-annual-national-report">19.7 million American adults (aged 12 and older) battled a substance use disorder in 2017</a>`
			},
			{
				title: '74%',
				desc: `Almost 74% of adults suffering from a substance use disorder in 2017 struggled with an alcohol use disorder`
			},
			{
				title: '38%',
				desc: `About 38% of adults in 2017 battled an illicit drug use disorder`
			},
			{
				title: '1 out of 8',
				desc: `In 2017, 1 out of every 8 adults struggled with both <a target="_blank" rel="noopener noreferrer" href="https://americanaddictioncenters.org/co-occurring-disorders">alcohol and drug use disorders simultaneously</a>`
			},
			{
				title: '8.5 million',
				desc: `In 2017, 8.5 million American adults suffered from both a mental health disorder and a substance use disorder, or co-occurring disorders (i.e., dual diagnosis)`
			},
			{
				title: '34%',
				desc: `Too often, those living with a dual diagnosis receive treatment for only one of their ailments. It is estimated that of the adults living with co-occurring disorders, 34 percent receive mental health treatment, 2 percent enroll in drug rehab, and 12 percent get the help they need for both disorders.`
			},
			{
				title: '$740 billion',
				desc: `Drug abuse and addiction cost American society more than $740 billion annually in lost workplace productivity, healthcare expenses, and crime-related costs`
			},
			{
				title: '20.7 million',
				desc: `In 2017, an estimated 20.7 million people age 12 and older needed treatment for a substance use disorder. Only 4 million people received treatment, or about 19% of those who needed it`
			},
			{
				title: '18 million',
				desc: `In 2017, of the more than 18 million people who needed but did not receive treatment for substance use, only 1 million, or 5.7%, of those people felt they needed treatment`
			},
			{
				title: '120,000 groups',
				desc: `<a target="_blank" rel="noopener noreferrer" href="http://www.aa.org/assets/en_US/smf-132_en.pdf">Alcoholics Anonymous</a> (AA) has more than 120,000 groups in more than 175 countries around the world, with more than 2 million members`
			},
			{
				title: '14,500',
				desc: `There are over 14,500 specialized substance abuse treatment facilities in the United States providing a variety of care options, including counseling, behavioral therapy, medication, case management, and other forms of care`
			},
			{
				title: '40% - 60%',
				desc: `The relapse rate for substance use disorders is estimated to be between 40% and 60%. This rate is similar to rates of relapse for other chronic diseases such as hypertension or asthma`
			},
			{
				title: '10%',
				desc: `Addiction is considered a highly treatable disease, and recovery is attainable. About 10% of American adults who are at least 18 years old say they are in recovery from an alcohol or drug abuse issue`
			}
		]
	},
	{
		title: 'Adolescents (aged 12-17)',
		content: [
			{
				title: '1 in 25',
				desc: `In 2017, approximately 4% of the <a target="_blank" rel="noopener noreferrer" href="https://americanaddictioncenters.org/rehab-guide/teens">American adolescent population</a> age 12 to 17 suffered from a substance use disorder; this equals 992,000 teens or 1 in 25 people in this age group`
			},
			{
				title: '443,000',
				desc: `About 443,000 adolescents age 12 to 17 had an alcohol use disorder in 2017, or 1.8% of adolescents`
			},
			{
				title: '3%',
				desc: `An estimated 741,000 adolescents suffered from an illicit drug use disorder in 2017, or about 3% of this population`
			}
		]
	},
	{
		title: 'Young adults (aged 18-25)',
		content: [
			{
				title: '5.1 million',
				desc: `About 5.1 million young adults age 18 to 25 battled a substance use disorder in 2017, which equates to 14.8% of this population and about 1 in 7 people`
			},
			{
				title: '10%',
				desc: `About 3.4 million young adults age 18 to 25 had an alcohol use disorder in 2017, or about 10% of young adults`
			},
			{
				title: '7.3%',
				desc: `About 2.5 million young adults had an illicit drug use disorder in 2017, or about 7.3% of this population`
			},
			{
				title: '200%',
				desc: `<a target="_blank" rel="noopener noreferrer" href="https://americanaddictioncenters.org/heroin-treatment/signs-of-heroin-use-in-teens">Heroin use among young adults</a> between 18 and 25 years old doubled in the past decade`
			}
		]
	},
	{
		title: 'Over age 26',
		content: [
			{
				title: '13.6 million',
				desc: `Approximately 13.6 million adults age 26 or older struggled with a substance use disorder in 2017, or 6.4% of this age group`
			},
			{
				title: '5%',
				desc: `About 10.6 million adults age 26 and older had an alcohol use disorder in 2017, or about 5% of this age group`
			},
			{
				title: '4.3 million',
				desc: `About 4.3 million adults age 26 or older had an illicit drug use disorder in 2017, or 2% of this age group`
			}
		]
	},
	{
		title: 'Men vs. women',
		content: [
			{
				title: '9.4% vs 5.2%',
				desc: `In 2017, about 9.4% of men and 5.2% of women age 12 and older had a substance use disorder`
			},
			{
				title: 'Men are 3x likely',
				desc: `Adult men are 2 to 3 times more likely than women to abuse illicit drugs than women, but women may be just as prone to addiction as men when they do abuse them`
			}
		]
	},
	{
		title: 'Prescription drugs',
		content: [
			{
				title: 'Most Common',
				desc: `The most common types of prescription drugs abused in 2017 were pain relievers, tranquilizers, stimulants, and sedatives`
			},
			{
				title: '1.7 million',
				desc: `In 2017, about 1.7 million people age 12 and older had a pain reliever use disorder, or about 0.6% of this population`
			},
			{
				title: 'Dependency',
				desc: `Women may more rapidly develop a prescription painkiller dependence than men. They are also more likely to have chronic pain, be prescribed pain relievers, and receive higher doses`
			},
			{
				title: 'Opioid Treatment',
				desc: `According to a study published in the journal <a target="_blank" rel="noopener noreferrer" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3117710/">Substance Abuse Treatment, Prevention, and Policy</a>, individuals who were admitted to opioid treatment programs who abused only prescription opioids, or those who abused both heroin and prescription opioids, were about 5 years younger than individuals admitted solely for heroin abuse or dependency`
			}
		]
	},
	{
		title: 'Heroin',
		content: [
			{
				title: '652,000',
				desc: `About 652,000 people age 12 and older had a heroin use disorder in 2017`
			},
			{
				title: '1/4',
				desc: `Almost a quarter of people who <a target="_blank" rel="noopener noreferrer" href="https://americanaddictioncenters.org/heroin-treatment">abuse heroin will become addicted to it</a>`
			},
			{
				title: 'Past 2 Decades',
				desc: `Heroin use has risen in most demographic groups in the United States over the past 2 decades, the <a target="_blank" rel="noopener noreferrer" href="https://www.cdc.gov/vitalsigns/heroin/">Centers for Disease Control and Prevention</a> (CDC) reports`
			},
			{
				title: '40x',
				desc: `Individuals addicted to alcohol are 2 times more likely to also be addicted to heroin, while those addicted to marijuana are 3 times more likely, individuals addicted to cocaine are 15 times more likely, and people addicted to prescription drugs are 40 times more likely`
			}
		]
	},
	{
		title: 'Cocaine',
		content: [
			{
				title: '966,000',
				desc: `About 966,000 American adults (over age 12) struggled with a cocaine use disorder in 2017`
			},
			{
				title: '637,000',
				desc: `That same year, 637,000 people age 12 and older <a target="_blank" rel="noopener noreferrer" href="https://americanaddictioncenters.org/cocaine-treatment">received treatment</a> for a cocaine use disorder either in their last or current stay in rehabilitation`
			}
		]
	},
	{
		title: 'Alcohol',
		content: [
			{
				title: '14.5 million',
				desc: `In 2017, an estimated 14.5 million American adults age 12 and older battled an alcohol use disorder, or 5.3% of this population`
			},
			{
				title: 'Over 50%',
				desc: `Over half of all American adults have a family history of <a target="_blank" rel="noopener noreferrer" href="https://americanaddictioncenters.org/alcoholism-treatment">problem drinking</a> or alcohol addiction`
			},
			{
				title: 'Over 10%',
				desc: `More than 10% of U.S. children live with a parent with alcohol problems`
			},
			{
				title: '88,000',
				desc: `An estimated 88,000 people die from alcohol-related causes annually`
			},
			{
				title: 'Alchohol & Death',
				desc: `Alcohol is the third-leading cause of preventable death in the United States`
			},
			{
				title: '78,529',
				desc: `47% of the 78,529 liver diseases among people age 12 and older in 2015 involved alcohol`
			},
			{
				title: '40%',
				desc: `40% of all hospital beds in the United States are used to treat conditions related to alcohol consumption`
			}
		]
	},
	{
		title: 'Marijuana',
		content: [
			{
				title: '4.1 million',
				desc: `Approximately 4.1 million American adults over the age of 12 battled a marijuana use disorder in 2017`
			},
			{
				title: 'Ages 12 - 25',
				desc: `The majority of people struggling with marijuana addiction in 2017 were between the ages of 12 and 25`
			},
			{
				title: '6%',
				desc: `Almost 6% of full-time college students in the United States were daily marijuana smokers in 2014. This is more than triple the number of daily smokers in this population 20 years prior`
			}
		]
	}
];
