import React, { useState, useEffect, useRef } from 'react';

import NumberFormat from 'react-number-format';
import MetaTags from 'react-meta-tags';
import PaymentInfo from '../reusable/PaymentInfo';
import constants from '../../constants';

import './sass/donatePage.scss';

const DonatePage = () => {
	const [selectedCard, setSelectedCard] = useState(donateContent[0]);
	const [finalPaymentInfo, setFinalPaymentInfo] = useState({
		type: 'donation',
		price: donateContent[0].price,
	});
	const [textFieldValues, setTextFieldValues] = useState({});

	const [scrollToItemOnSubmit, setScrollToItemOnSubmit] = useState();

	return (
		<div className='donate-page default-width'>
			<MetaTags>
				<title>Donate to Beit Nesicha</title>
				<meta name='description' content='Sponsor treatment for a Jewish princess in Am Yisroel seeking help to have a better life' />
				<meta property='og:title' content='Donate to Beit Nesicha' />
			</MetaTags>

			<div className='diff-title'>
				<h3 className='title'>Make a Donation Today</h3>
				<div className='bg-logo'></div>
			</div>
			
			<h3 style={{ textAlign: 'center', marginTop: 50, fontWeight: 200 }}>
				We are not accepting online donations now. Please call us at{' '}
				<a href={constants.PHONECALL} style={{ color: 'inherit', textDecoration: 'underline' }}>
					(415) RELIEF-0
				</a>{' '}
				<span style={{ fontSize: 13 }}>(415 735-4330)</span> to make your donation.
			</h3>

			{/* <h4 className='sub-title'>Sponsor treatment for a Jewish princess in Am Yisroel seeking help to have a better life</h4>

			<div className='donate-card-container'>
				<DonateCardDivide
					content={donateContent[0]}
					selectedCard={selectedCard}
					setSelectedCard={setSelectedCard}
					finalPaymentInfo={finalPaymentInfo}
					setFinalPaymentInfo={setFinalPaymentInfo}
					setScrollToItemOnSubmit={setScrollToItemOnSubmit}
					textFieldValues={textFieldValues}
					setTextFieldValues={setTextFieldValues}
				/>

				<DonateCardMultiply
					content={donateContent[1]}
					selectedCard={selectedCard}
					setSelectedCard={setSelectedCard}
					finalPaymentInfo={finalPaymentInfo}
					setFinalPaymentInfo={setFinalPaymentInfo}
					setScrollToItemOnSubmit={setScrollToItemOnSubmit}
					textFieldValues={textFieldValues}
					setTextFieldValues={setTextFieldValues}
				/>

				<DonateCardMultiply
					content={donateContent[2]}
					selectedCard={selectedCard}
					setSelectedCard={setSelectedCard}
					finalPaymentInfo={finalPaymentInfo}
					setFinalPaymentInfo={setFinalPaymentInfo}
					setScrollToItemOnSubmit={setScrollToItemOnSubmit}
					textFieldValues={textFieldValues}
					setTextFieldValues={setTextFieldValues}
				/>

				<DonateCustom
					content={donateContent[3]}
					selectedCard={selectedCard}
					setSelectedCard={setSelectedCard}
					finalPaymentInfo={finalPaymentInfo}
					setFinalPaymentInfo={setFinalPaymentInfo}
					setScrollToItemOnSubmit={setScrollToItemOnSubmit}
					textFieldValues={textFieldValues}
					setTextFieldValues={setTextFieldValues}
				/>
			</div>

			<PaymentInfo finalPaymentInfo={finalPaymentInfo} scrollToItemOnSubmit={scrollToItemOnSubmit} /> */}
		</div>
	);
};

export default DonatePage;

const DonateCardMultiply = (props) => {
	const [monthAmount, setMonthAmount] = useState({
		months: 1,
		price: props.content.price,
	});
	const [isMonthlyChecked, setIsMonthlyChecked] = useState(false);
	const textfieldRef = useRef(null);

	const descriptionTextSplit = props.content.description.split(' textfield ');
	let descriptionSecondHalf = descriptionTextSplit[1];

	if (monthAmount.months <= 1)
		descriptionSecondHalf = descriptionTextSplit[1].slice(0, 5) + descriptionTextSplit[1].slice(6, descriptionTextSplit[1].length);

	const monthChangeHandler = ({ target }) => {
		props.setSelectedCard(props.content);
		let months = parseFloat(target.value);

		if (months < 1) months = 1;

		const price = months * parseFloat(props.content.price);

		const newMonthAmount = {
			months,
			price: price || props.content.price,
		};
		setMonthAmount(newMonthAmount);

		let newFinalPaymentInfo = {
			type: 'donation',
			price,
		};
		if (isMonthlyChecked) newFinalPaymentInfo.monthly = newMonthAmount.price;

		props.setFinalPaymentInfo(newFinalPaymentInfo);
	};

	const monthlyDonationChangeHandler = () => {
		props.setSelectedCard(props.content);
		let newFinalPaymentInfo = { ...props.finalPaymentInfo };
		if (!isMonthlyChecked) newFinalPaymentInfo.monthly = props.finalPaymentInfo.price;
		else delete newFinalPaymentInfo.monthly;
		props.setFinalPaymentInfo(newFinalPaymentInfo);

		setIsMonthlyChecked(!isMonthlyChecked);
	};

	const checked = props.content.description === props.selectedCard.description;

	return (
		<div className='donate-card'>
			<input
				className='donate-input'
				type='radio'
				name='donate'
				id={props.content.description.split(' ').join('')}
				checked={checked}
				onChange={() => {
					props.setScrollToItemOnSubmit();
					const value = {
						target: {
							value: monthAmount.months,
						},
					};
					monthChangeHandler(value);
				}}
			/>
			<label className='donate-wrapper' htmlFor={props.content.description.split(' ').join('')}>
				<div className='donate-top'>
					<h4 className='amount'>
						<NumberFormat thousandSeparator={true} prefix={'$'} value={monthAmount.price} displayType={'text'} />
					</h4>

					<div className='checkmark'>
						<span>&#10003;</span>
					</div>
				</div>

				<h4 className='description'>
					{descriptionTextSplit[0]} <TextField value={monthAmount.months} onChange={monthChangeHandler} type='number' />{' '}
					{descriptionSecondHalf}
				</h4>
				<div className='spacer-multiply'></div>

				<div className='montly-opt-in'>
					<input
						type='checkbox'
						id={`monthly${descriptionSecondHalf.split(' ').join('')}`}
						checked={isMonthlyChecked}
						onChange={monthlyDonationChangeHandler}
						ref={textfieldRef}
					/>
					<label htmlFor={`monthly${descriptionSecondHalf.split(' ').join('')}`}>
						<div className='checkbox'>
							<span>&#10003;</span>
						</div>
						Make this a monthly donation
					</label>
				</div>
			</label>
		</div>
	);
};

const DonateCardDivide = (props) => {
	const [monthAmount, setMonthAmount] = useState({
		months: 1,
		price: props.content.price,
	});

	const changeHandler = ({ target }) => {
		props.setSelectedCard(props.content);
		let months = parseFloat(target.value);

		if (months < 1) months = 1;
		else if (months > 10) months = 10;

		let price = parseFloat(props.content.price) / months;

		price = Math.round(price * 100) / 100;

		const newMonthAmount = {
			months,
			price: price || props.content.price,
		};
		setMonthAmount(newMonthAmount);

		let newFinalPaymentInfo = {
			type: 'donation',
			price,
		};

		if (months > 1) {
			newFinalPaymentInfo.paymentPlan = {
				monthlyPrice: price,
				todaysPrice: price,
				term: months - 1,
			};
		}
		props.setFinalPaymentInfo(newFinalPaymentInfo);
	};

	const checked = props.content.description === props.selectedCard.description;

	return (
		<div className='donate-card'>
			<input
				className='donate-input'
				type='radio'
				name='donate'
				id={props.content.description.split(' ').join('')}
				checked={checked}
				onChange={() => {
					props.setScrollToItemOnSubmit();
					const value = {
						target: {
							value: monthAmount.months,
						},
					};
					changeHandler(value);
				}}
			/>
			<label className='donate-wrapper' htmlFor={props.content.description.split(' ').join('')}>
				<div className='donate-top'>
					<h4 className='amount'>
						<NumberFormat thousandSeparator={true} prefix={'$'} value={parseFloat(props.content.price)} displayType={'text'} />
					</h4>

					<div className='checkmark'>
						<span>&#10003;</span>
					</div>
				</div>
				<h4 className='description'>
					{props.content.description} <input className='textfield' value={monthAmount.months} onChange={changeHandler} type='number' />{' '}
					monthly payment
					{monthAmount.months > 1 && `s`} of{' '}
					<NumberFormat thousandSeparator={true} prefix={'$'} value={parseFloat(monthAmount.price)} displayType={'text'} />
				</h4>
				<div className='spacer-divide'></div>
			</label>
		</div>
	);
};

const DonateCustom = (props) => {
	const [monthAmount, setMonthAmount] = useState({
		price: null,
	});

	const [isMonthlyChecked, setIsMonthlyChecked] = useState(false);
	let textfieldRef = useRef(null);
	const customDonationRefContainerRef = useRef(null);

	const cardChangeHanlder = () => {
		textfieldRef.focus();

		props.setScrollToItemOnSubmit(customDonationRefContainerRef);

		const value = {
			floatValue: parseFloat(monthAmount.price),
		};
		priceChangeHandler(value);
	};

	const priceChangeHandler = (value) => {
		props.setSelectedCard(props.content);
		const price = value.floatValue;

		setMonthAmount({ price });

		let newFinalPaymentInfo = {
			type: 'donation',
			price: price || 0,
		};

		if (value.monthlyChange && !isMonthlyChecked) newFinalPaymentInfo.monthly = price || 0;
		else if (!value.monthlyChange && isMonthlyChecked) newFinalPaymentInfo.monthly = price || 0;

		if (price && price > 0) props.setScrollToItemOnSubmit();
		else props.setScrollToItemOnSubmit(customDonationRefContainerRef);
		props.setFinalPaymentInfo(newFinalPaymentInfo);
	};

	const monthlyDonationChangeHandler = () => {
		props.setSelectedCard(props.content);
		let newFinalPaymentInfo = { ...props.finalPaymentInfo };
		if (!isMonthlyChecked) newFinalPaymentInfo.monthly = props.content.price;
		else delete newFinalPaymentInfo.monthly;
		props.setFinalPaymentInfo(newFinalPaymentInfo);

		setIsMonthlyChecked(!isMonthlyChecked);
		const value = {
			floatValue: parseFloat(monthAmount.price),
			monthlyChange: true,
		};
		priceChangeHandler(value);
	};

	const checked = props.selectedCard.description === 'custom';

	return (
		<div className='donate-card' ref={customDonationRefContainerRef}>
			<input
				className='donate-input'
				type='radio'
				name='donate'
				id={props.content.description.split(' ').join('')}
				checked={checked}
				onChange={cardChangeHanlder}
			/>
			<label className='donate-wrapper' htmlFor={props.content.description.split(' ').join('')}>
				<div className='donate-top'>
					<h4 className='amount'>
						<NumberFormat
							thousandSeparator={true}
							prefix={'$'}
							value={monthAmount.price || 0.0}
							decimalScale={2}
							fixedDecimalScale={true}
							displayType={'text'}
						/>
						<span className='line'>|</span>
						<p>Custom</p>
					</h4>

					<div className='checkmark'>
						<span>&#10003;</span>
					</div>
				</div>

				<h4 className='description donate'>
					I would like to help support someone's treatment with the donation amount of{' '}
					{/* <TextField
						value={monthAmount.price}
						onChange={priceChangeHandler}
						placeholder='$0.00'
						InputProps={{
							inputComponent: NumberFormatCustom
						}}
						inputRef={textfieldRef}
					/> */}
					<NumberFormat
						className='textfield custom'
						thousandSeparator={true}
						prefix={'$'}
						onValueChange={priceChangeHandler}
						decimalScale={2}
						// fixedDecimalScale={true}
						value={monthAmount.price}
						allowNegative={false}
						getInputRef={(el) => (textfieldRef = el)}
						placeholder='$0.0'
					/>
				</h4>

				<div className='montly-opt-in'>
					<input type='checkbox' id='monthlyCustom' checked={isMonthlyChecked} onChange={monthlyDonationChangeHandler} />
					<label htmlFor='monthlyCustom'>
						<div className='checkbox'>
							<span>&#10003;</span>
						</div>
						Make this a monthly donation
					</label>
				</div>
			</label>
		</div>
	);
};

const TextField = (props) => {
	return <input className='textfield' type='text' {...props} />;
};

const donateContent = [
	{
		price: 36000,
		description: 'I would like to sponsor the full treatment in',
	},
	{
		price: 6000,
		description: 'I would like to sponsor textfield months of Residential treatment',
	},
	{
		price: 3000,
		description: 'I would like to sponsor textfield months of Transitional Living',
	},
	{
		amount: 0.0,
		description: `custom`,
	},
];
