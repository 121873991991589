// const INFOEMAIL = 'info@beitnesicha.org';
const INFOEMAIL = 'info@beitnesicha.org';
const PHONE = '(415) RELIEF-0';
const PHONE_NUMBERS = '(415) 735433-0';
const PHONECALL = 'tel://1-415-735-4330';

export default {
	INFOEMAIL,
	PHONE,
	PHONE_NUMBERS,
	PHONECALL
};
