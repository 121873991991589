import React from 'react';
import { statSpecificData, statsOtherData } from '../../content/stats';

import './definitionsAndStatsComponent.scss';

const StatsContainer = ({ title }) => {
	return (
		<>
			<div className='diff-title default-width'>
				<h3 className='title'>{title || 'Statistics'}</h3>
			</div>

			<div className='default-width'>
				<div className='section-container'>
					<h4 className='sub-title'>
						Alcohol/Substance Abuse and Addiction
						<span></span>
					</h4>

					<div className='collection-container'>
						{statSpecificData.map(content => {
							let title = content.title;
							title = title.split('(').join('<span>(');
							title = title.split(')').join(')</span>');

							return (
								<a
									href={`/statistics-and-definitions/stats/${content.title
										.toLocaleLowerCase()
										.split(' ')
										.join('-')}`}
									className='collection'
									key={content.title.split(' ').join('')}
								>
									<h4
										dangerouslySetInnerHTML={{
											__html: title
										}}
									></h4>
								</a>
							);
						})}
					</div>
				</div>

				<div className='section-container'>
					<h4 className='sub-title'>
						Eating Disorders and Other Mental Health Issues
						<span></span>
					</h4>

					<div className='collection-container'>
						{statsOtherData.map(content => {
							let title = content.title;
							title = title.split('(').join('<span>(');
							title = title.split(')').join(')</span>');

							return (
								<a
									href={`/statistics-and-definitions/stats/${content.title
										.toLocaleLowerCase()
										.split(' ')
										.join('-')}`}
									className='collection'
									key={content.title.split(' ').join('')}
								>
									<h4
										dangerouslySetInnerHTML={{
											__html: title
										}}
									></h4>
								</a>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
};

export default StatsContainer;
