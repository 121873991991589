import React, { Component } from 'react';
import '../sass/navbar.scss';
import DonateBtn from './reusable/DonateBtn';
import Button from './reusable/Button';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { FirstMenu, SecondMenu } from './reusable/Menu';

class NavBar extends Component {
	state = { menuIsOpen: false };

	toggleMenuHandler = () => {
		const isOpen = this.state.menuIsOpen;

		if (isOpen) this.closeMenuHandler();
		else this.openMenuHandler();
	};

	closeMenuHandler = () => {
		this.setState({ menuIsOpen: false });
	};

	openMenuHandler = () => {
		this.setState({ menuIsOpen: true });
	};

	render() {
		const menuIsOpen = this.state.menuIsOpen;
		const isOpenClassName = menuIsOpen ? 'open' : '';

		const bgComponent = (
			<div
				className={`nav-bg ${isOpenClassName}`}
				onClick={this.closeMenuHandler}
			/>
		);

		return (
			<>
				{/* Desktop Menu */}
				<div className='screen-xl'>
					<nav className='screen-xl-inner default-width'>
						<div className='inner-nav'>
							<ul>
								<li className='menu-item'>
									<a href='/'>Home</a>
								</li>
								<li className='menu-item'>
									<a href='/about'>About</a>
									<ul className='sub-menu'>
										<li>
											<a href='/about'>About Us</a>
										</li>
										<li>
											<a href='/staff'>Our Staff</a>
										</li>
										{/* <li>
											<a href='/our-staff'>
												Meet the Staff
											</a>
										</li> */}
										<li>
											<a href='/our-schedule'>
												Our Schedule
											</a>
										</li>
										<li>
											<a href='/preparing-for-your-stay'>
												Preparing for your Stay
											</a>
										</li>
									</ul>
								</li>

								<li className='menu-item'>
									<a href='/who-needs-treatment'>Treatment</a>

									<ul className='sub-menu'>
										<li>
											<a href='/who-needs-treatment'>
												Who Needs Treatment
											</a>
										</li>
										<li>
											<a href='/statistics-and-definitions'>
												Statistics and Definitions
											</a>
										</li>
									</ul>
								</li>

								<li className='menu-item'>
									<a href='/services'>Services</a>
								</li>

								<li className='menu-item'>
									<a href='/what-is-aca'>What is ACA</a>
								</li>

								{/* <li className='menu-item'>
									<a href='/payment'>Payment</a>
								</li> */}

								{/* <li className='menu-item'>
									<a href='/payment'>Admission</a>

									<ul className='sub-menu'>
										<li>
											<a href='/payment'>Payment</a>
										</li>
										<li>
											<a href='/preparing-for-your-stay'>
												Preparing for your Stay
											</a>
										</li>
									</ul>
								</li> */}

								{/* <li className='menu-item'>
									<a href='/info/definitions'>Definitions</a>
								</li> */}
							</ul>

							<div className='right-nav'>
								<Button
									text='Contact'
									link='/contact'
									inverted={true}
								/>
								<DonateBtn />
								<a href='/' className='logo-link'>
									<img
										src='/images/logo.png'
										alt='Beit Nesicha'
									/>
								</a>
							</div>
						</div>

						<div className='nav-filler' />
					</nav>
				</div>

				{/* Mobile Menu */}
				<div className='screen-m'>
					<div className='screen-m-inner default-width'>
						<div className='left'>
							<div
								className='hamburger-menu'
								onClick={this.openMenuHandler}
							>
								<span />
								<span />
								<span />
							</div>

							<DonateBtn inverted='true' />
						</div>

						<a href='/' className='logo-link'>
							<img src='/images/logo.png' alt='Beit Nesicha' />
						</a>

						<nav>
							<ReactCSSTransitionGroup
								transitionName='nav-bg-animate'
								transitionEnterTimeout={500}
								transitionLeaveTimeout={300}
							>
								{menuIsOpen ? bgComponent : null}
							</ReactCSSTransitionGroup>

							<div className={`menu-wrapper ${isOpenClassName}`}>
								<button
									className='close-menu'
									onClick={this.closeMenuHandler}
								>
									<span />
									<span />
								</button>
								<ul>
									{/* <FirstMenu />
									<SecondMenu /> */}
									<li className='menu-item'>
										<a href='/'>Home</a>
									</li>
									<li>
										<a href='/about'>About Us</a>
									</li>
									<li>
										<a href='/our-schedule'>Our Schedule</a>
									</li>
									<li>
										<a href='/staff'>Our Staff</a>
									</li>
									<li>
										<a href='/what-is-aca'>What is ACA</a>
									</li>
									<li>
										<a href='/who-needs-treatment'>
											Who Needs Treatment
										</a>
									</li>
									<li>
										<a href='/statistics-and-definitions'>
											Statistics and Definitions
										</a>
									</li>
									<li className='menu-item'>
										<a href='/services'>Services</a>
									</li>
									{/* <li>
										<a href='/payment'>Payment</a>
									</li> */}
									<li>
										<a href='/preparing-for-your-stay'>
											Preparing for your Stay
										</a>
									</li>
								</ul>
							</div>
						</nav>
					</div>
				</div>
			</>
		);
	}
}

export default NavBar;
