import React from 'react';
import StatsComponent from '../reusable/StatsComponent';
import DefinitionsComponent from '../reusable/DefinitionsComponent';
import Quote from '../reusable/Quote';
import MetaTags from 'react-meta-tags';

import './sass/statsAndDefinitions.scss';

const StatsAndDefinitions = () => {
	const quoteContent =
		'On average, 6-10 addiction cases and 6-10 mental illness cases are opened weekly.';

	return (
		<div className='stats-and-definitions'>
			<MetaTags>
				<title>Stats and Definitions</title>
				<meta
					name='description'
					content='On average, 6-10 addiction cases and 6-10 mental illness cases are opened weekly.'
				/>
				<meta property='og:title' content='Stats and Definitions' />
			</MetaTags>

			<div className='amudim-stats default-width'>
				<div className='bg-logo'></div>
				<div className='diff-title'>
					<h3 className='title'>Amudim Statistics</h3>
				</div>

				<p>
					Amudim provides a weekly newsletter with the number of cases
					opened per week. On average,{' '}
					<span>6-10 addiction cases</span> and{' '}
					<span>6-10 mental illness cases are opened weekly</span>.
					These are Jews seeking help. The numbers increase when we
					factor in how many are seeking help from other places. The
					numbers painfully increase when we factor in how many need
					help but are not actively seeking it. We cannot deny that
					our communities are in desperate need of support, education,
					and interventions to help individuals struggling with
					addiction and mental health issues. Losing one of our family
					members to overdose, suicide, or mental illness is already
					too much.
				</p>
			</div>

			<StatsComponent />

			<Quote content={quoteContent} fullWidth='true' />

			<DefinitionsComponent />
		</div>
	);
};

export default StatsAndDefinitions;
