import React, { useState, useEffect } from 'react';
import constants from '../../constants';

import './sass/payments.scss';
import MetaTags from 'react-meta-tags';

import PaymentInfo from '../reusable/PaymentInfo';

const Payments = () => {
	const [selectedProgram, setSelectedProgram] = useState({
		program: programContent[0],
		paymentPlan: false
	});
	const [paymentPlan, setPaymentPlan] = useState({
		today: programContent[0].price,
		monthly: '$0',
		months: '0'
	});

	const [finalPaymentInfo, setFinalPaymentInfo] = useState({});

	useEffect(() => {
		let newPaymentPlan = {};

		if (selectedProgram.paymentPlan) {
			newPaymentPlan = {
				...selectedProgram.program.paymentPlanNumbers
			};
		} else {
			newPaymentPlan = {
				today: selectedProgram.program.price,
				monthly: '$0',
				months: '0'
			};
		}

		newPaymentPlan.today = newPaymentPlan.today.replace(/[^0-9.-]+/g, '');
		newPaymentPlan.monthly = newPaymentPlan.monthly.replace(/[^0-9.-]+/g, '');

		newPaymentPlan.today = parseInt(newPaymentPlan.today, 10);
		newPaymentPlan.monthly = parseInt(newPaymentPlan.monthly, 10);

		const todayPercentOfIncrease = (3 / 100) * newPaymentPlan.today;
		newPaymentPlan.today += todayPercentOfIncrease;
		newPaymentPlan.today = newPaymentPlan.today.toFixed(2);

		const monthlyPercentOfIncrease = (3 / 100) * newPaymentPlan.monthly;
		newPaymentPlan.monthly += monthlyPercentOfIncrease;
		newPaymentPlan.monthly = newPaymentPlan.monthly.toFixed(2);

		setPaymentPlan(newPaymentPlan);

		let newFinalPaymentPlan = {
			type: 'payment',
			price: newPaymentPlan.today
		};

		if (selectedProgram.paymentPlan) {
			newFinalPaymentPlan.paymentPlan = {
				monthlyPrice: newPaymentPlan.monthly,
				todaysPrice: newPaymentPlan.today,
				term: selectedProgram.program.paymentPlanNumbers.months
			};
		}

		setFinalPaymentInfo(newFinalPaymentPlan);
	}, [selectedProgram]);

	useEffect(() => {}, [paymentPlan]);

	const PaymentInfoChildren = () => {
		return (
			<>
				<TermsSection />
				<PaymentReview paymentPlan={paymentPlan} />
			</>
		);
	};

	return (
		<div className='payments default-width'>
			<div className='bg-logo'></div>
			<div className='diff-title'>
				<h3 className='title'>Treatment Info</h3>
			</div>

			<div className='services'>
				<div className='img-container'>
					<h3 className='title'>Residential Treatment Program</h3>
					<img src='images/home/facility copy2.jpg' alt='' />
					<div className='details-container'>
						<div className='detail'>
							<h4>Length</h4>
							<span>120 days (4 months)</span>
						</div>
						<div className='detail'>
							<h4>Location</h4>
							<span>Israel</span>
						</div>
						<div className='detail'>
							<h4>Cost</h4>
							<span>$24,000</span>
						</div>
					</div>
				</div>

				<div className='img-container'>
					<h3 className='title'>Transitional Living program</h3>
					<img src='/images/home/facility right.jpg' alt='' />
					<div className='details-container'>
						<div className='detail'>
							<h4>Length</h4>
							<span>120 days (4 months)</span>
						</div>
						<div className='detail'>
							<h4>Location</h4>
							<span>Israel or United States</span>
						</div>
						<div className='detail'>
							<h4>Cost</h4>
							<span>$12,000</span>
						</div>
					</div>
				</div>
			</div>

			{/* <h3 style={{ textAlign: 'center', marginTop: 100, fontWeight: 200 }}>
				We are not accepting online payments now. Please call us at{' '}
				<a href={constants.PHONECALL} style={{ color: 'inherit', textDecoration: 'underline' }}>
					(415) RELIEF-0
				</a>{' '}
				<span style={{ fontSize: 13 }}>(415 735-4330)</span> for any questions.
			</h3> */}

			<div className='cost-breakdown'>
				<div className='diff-title'>
					<h3 className='title'>Cost Breakdown</h3>
				</div>

				<div className='payment-container'>
					{programContent.map((content, index) => {
						return (
							<PriceContainerComponent
								key={content.title}
								program={content}
								selectedProgram={selectedProgram}
								setSelectedProgram={setSelectedProgram}
							/>
						);
					})}
				</div>
			</div>

			<PaymentInfo
				Children={PaymentInfoChildren}
				finalPaymentInfo={finalPaymentInfo}
			/>
		</div>
	);
};

export default Payments;

const PriceContainerComponent = props => {
	const { title } = props.program;

	const [expandClassName, setExpandClassName] = useState('');

	const expandHandler = () => {
		if (expandClassName === '') setExpandClassName('expand');
		else setExpandClassName('');
	};

	const cardChangeHandler = () => {
		const newProgram = { program: props.program, paymentPlan: false };
		props.setSelectedProgram(newProgram);
	};

	const optInForPaymentPlanHandler = () => {
		let paymentPlan = !props.selectedProgram.paymentPlan;

		if (props.program.title !== props.selectedProgram.program.title) {
			paymentPlan = true;
		}

		const newProgram = {
			program: props.program,
			paymentPlan
		};

		props.setSelectedProgram(newProgram);

		if (newProgram.paymentPlan) {
			setExpandClassName('expand');
		} else {
			setExpandClassName('');
		}
	};

	return (
		<div className='payment'>
			<input
				type='radio'
				name='payment'
				id={title.split(' ').join('')}
				onChange={cardChangeHandler}
				checked={props.program.title === props.selectedProgram.program.title}
			/>
			<label className='cost-breakdown-label' htmlFor={title.split(' ').join('')}>
				<div className={`text ${expandClassName}`}>
					<div className='details-container'>
						<h3 className='title'>
							{title.split(' ')[0]}
							<span>
								{title.split(' ')[1]} {title.split(' ')[2]}
							</span>
						</h3>
						<div className='radio-container'>
							<span>&#10003;</span>
						</div>
					</div>
					<div className='details-container'>
						<div className='total-price detail'>
							<h4>{props.program.price}</h4>
							<span className='title'>{props.program.length}</span>
						</div>

						<div className='detail'></div>
					</div>

					<div className='line'></div>

					<div className='payment-option-container'>
						<input
							type='checkbox'
							id={`payemnt-plan-input${title}`}
							checked={
								props.program.title === props.selectedProgram.program.title &&
								props.selectedProgram.paymentPlan
							}
							onChange={optInForPaymentPlanHandler}
						/>
						<label htmlFor={`payemnt-plan-input${title}`}>
							<div className='checkbox'></div>
							<button onClick={expandHandler}>
								<h5>Opt in for Payment Plan</h5>
								<img src='/images/icons/expand_more.svg' alt='' />
							</button>
						</label>

						<div className='payment-plan'>
							<ul>
								{props.program.paymentPlan.map(rule => {
									return (
										<li key={`${title}${rule}rule`}>
											<span></span>
											{rule}
										</li>
									);
								})}
							</ul>
						</div>
					</div>
				</div>
			</label>
		</div>
	);
};

const TermsSection = () => {
	return (
		<div className='terms-section'>
			<ul>
				<li>
					<span></span>
					Payments can be made in four easy ways: Cash, Check, Bank Transfer, or Credit Card. Chargebacks will
					not be acceptable to dispute.
				</li>
				<li>
					<span></span>
					Billing is based on 40-day increments. In the event of leaving the program before completion, the
					current 40-day tier will not be reimbursed, but any monies paid for remaining treatment time will be
					returned within 10 business days.
				</li>
			</ul>
			<ul>
				<li>
					<span></span>
					In the event that a deposit was made but the client never entered treatment, there will be a
					processing fee of $150, and the remaining deposit will be returned.
				</li>
				<li>
					<span></span>
					All individuals entering Beit Nesicha must be detoxed (or letter from physician), have medical
					insurance (recognized in Israel), and must have a stable place (family member or friend) in Israel -
					in the event of leaving the program before completion.
				</li>
			</ul>
		</div>
	);
};

const PaymentReview = props => {
	let todaysPayment = parseInt(props.paymentPlan.today).toLocaleString('en', {
		minimumFractionDigits: 2
	});
	let monthlyPayment = parseInt(props.paymentPlan.monthly).toLocaleString('en', {
		minimumFractionDigits: 2
	});

	return (
		<>
			<div className='payment-review'>
				<h3>Payment Amount</h3>
				<div className='payment-section'>
					<div className='payment'>
						<h4 className='total'>${todaysPayment}</h4>
						<div className='title'>Today</div>
					</div>

					<div className='payment'>
						<h4 className='total'>${monthlyPayment}</h4>
						<div className='title'>{props.paymentPlan.months} Monthly Payments</div>
					</div>
				</div>
			</div>
			<p className='processing-fee'>There is a 3% processing fee for online payments.</p>
		</>
	);
};

const programContent = [
	{
		title: 'Full Treatment',
		price: '$36,000',
		length: '240 days (8 months)',
		paymentPlan: ['$12,000 must be paid upfront', '$24,000 paid in 4 monthly payments'],
		paymentPlanNumbers: {
			today: '$12,000',
			monthly: '$6,000',
			months: '4'
		}
	},
	{
		title: 'Residential Program Treatment',
		price: '$24,000',
		length: '120 days (4 months)',
		paymentPlan: ['$6,000 must be paid upfront', '$18,000 paid in 3 monthly payments'],
		paymentPlanNumbers: {
			today: '$6,000',
			monthly: '$6,000',
			months: '3'
		}
	},
	{
		title: 'Transitional Program Living',
		price: '$12,000',
		length: '120 days (4 months)',
		paymentPlan: ['$3,000 must be paid upfront', '$9,000 paid in 3 monthly payments'],
		paymentPlanNumbers: {
			today: '$3,000',
			monthly: '$3,000',
			months: '3'
		}
	}
];
