import React from 'react';
import './button.scss';

const Button = props => {
	const invertedClass = props.inverted ? 'inverted' : '';
	return (
		<a className={`button ${invertedClass}`} href={props.link}>
			{props.text}
		</a>
	);
};

export default Button;
