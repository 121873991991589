import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Cards from 'react-credit-cards';
import NumberFormat from 'react-number-format';
import Joi from 'joi-browser';
import scrollToComponent from 'react-scroll-to-component';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router-dom';
import http from '../../http';

import constants from '../../constants';

import './paymentInfo.scss';
import './card.scss';

const PaymentInfo = props => {
	const [userInfoData, setUserInfoData] = useState({
		fullName: '',
		email: '',
		phone: '',
		address: '',
		city: '',
		state: '',
		zip: '',
		ccName: '',
		ccNum: '',
		ccExp: '',
		cvv2: '',
		focus: ''
	});
	const [submitBtnClass, setSubmitBtnClass] = useState('');
	const [submitError, setSubmitError] = useState('');

	let PaymentInfoRef = React.createRef();

	const scrollToPaymentInfo = () => {
		scrollToComponent(PaymentInfoRef.current, {
			offset: -90,
			align: 'top',
			duration: 500,
			ease: 'inOutQuad'
		});
	};

	const [errors, setErrors] = useState({ input: '', message: '' });

	const textFieldChangeHandler = (textFieldName, value) => {
		const newData = { ...userInfoData };
		newData[textFieldName] = value;

		setUserInfoData(newData);

		if (value) setErrors({ input: '', message: '' });
	};

	const focusHandler = e => {
		const newUserInfoData = { ...userInfoData };
		newUserInfoData.focus = e.target.name;
		setUserInfoData(newUserInfoData);
	};

	const submitHandler = async () => {
		if (props.scrollToItemOnSubmit) {
			scrollToComponent(props.scrollToItemOnSubmit.current, {
				offset: -90,
				align: 'top',
				duration: 500,
				ease: 'inOutQuad'
			});
			return;
		}
		const newUserInfoData = { ...userInfoData };
		delete newUserInfoData.focus;

		const { error } = Joi.validate(newUserInfoData, schema);
		if (error) {
			const errorObj = {
				input: error.details[0].path[0],
				message: error.details[0].message
			};
			scrollToPaymentInfo();
			setErrors(errorObj);
			return;
		}

		setSubmitBtnClass('load');
		try {
			let payload = { ...userInfoData, ...props.finalPaymentInfo };
			delete payload.focus;
			// const result = await http.post('/api/payment/donate', payload);

			const result = await http.post('/', payload);

			// if (result.data === 'Approved') {
			if (result.data.body === 'Approved') {
				props.history.push('/payment-complete', {
					name: userInfoData.fullName,
					email: userInfoData.email,
					...props.finalPaymentInfo
				});
			} else {
				setSubmitError('error');
				setSubmitBtnClass('');
				console.log(result);
			}
		} catch (error) {
			setSubmitError('error');
			setSubmitBtnClass('');
			console.log(error);
		}

		// type: 'donation',
		// 	name: 'Chaim Hershy',
		// 	email: 'chaimhershy@gmail.com',
		// 	price: '$20,000',
		// 	monthly: '$2,500',
		// 	paymentPlan: {
		// 		monthlyPrice: '$5,000',
		// 		todaysPrice: '$10,000',
		// 		term: 5
		// 	}
	};

	const schema = {
		fullName: Joi.string()
			.required()
			.label('Full Name'),
		phone: Joi.string()
			.required()
			.label('Phone'),
		email: Joi.string()
			.email()
			.required()
			.label('email'),
		address: Joi.string()
			.required()
			.label('Address'),
		city: Joi.string()
			.required()
			.label('City'),
		state: Joi.string()
			.required()
			.label('State'),
		zip: Joi.number()
			.required()
			.label('zip'),
		ccNum: Joi.number()
			.required()
			.label('Card Number'),
		ccName: Joi.string()
			.required()
			.label('Name on Card'),
		ccExp: Joi.string()
			.required()
			.label('Expiration'),
		cvv2: Joi.string()
			.required()
			.label('CVC')
	};

	return (
		<div className='payment-info-container' ref={PaymentInfoRef}>
			<div className='payment-info'>
				<div className='info-section'>
					<h3 className='title'>
						<span>01</span>Billing Info
					</h3>

					<div className='textfields'>
						<div className='container'>
							<RenderInput
								shouldDisable={submitBtnClass === 'load'}
								errors={errors}
								name='fullName'
								label='Full Name'
								value={userInfoData.fullName}
								textFieldChangeHandler={textFieldChangeHandler}
							/>

							<RenderInput
								shouldDisable={submitBtnClass === 'load'}
								errors={errors}
								name='phone'
								label='Phone'
								value={userInfoData.phone}
								textFieldChangeHandler={textFieldChangeHandler}
							/>
						</div>

						<div className='container'>
							<RenderInput
								shouldDisable={submitBtnClass === 'load'}
								errors={errors}
								name='email'
								label='Email'
								value={userInfoData.email}
								textFieldChangeHandler={textFieldChangeHandler}
							/>
						</div>

						<div
							className='container'
							style={{ marginTop: '20px' }}
						>
							<RenderInput
								shouldDisable={submitBtnClass === 'load'}
								errors={errors}
								name='address'
								label='Address'
								value={userInfoData.address}
								textFieldChangeHandler={textFieldChangeHandler}
							/>
						</div>

						<div className='container'>
							<RenderInput
								shouldDisable={submitBtnClass === 'load'}
								errors={errors}
								style={{ flex: 2 }}
								name='city'
								label='City'
								value={userInfoData.city}
								textFieldChangeHandler={textFieldChangeHandler}
							/>

							<RenderInput
								shouldDisable={submitBtnClass === 'load'}
								errors={errors}
								name='state'
								label='State'
								value={userInfoData.state}
								textFieldChangeHandler={textFieldChangeHandler}
							/>

							<RenderInput
								shouldDisable={submitBtnClass === 'load'}
								errors={errors}
								name='zip'
								label='Zip'
								value={userInfoData.zip}
								textFieldChangeHandler={textFieldChangeHandler}
							/>
						</div>
					</div>
				</div>

				<div className='info-section'>
					<h3 className='title'>
						<span>02</span>Payment Info
					</h3>

					<div className='textfields'>
						<div className='container'>
							<RenderInput
								shouldDisable={submitBtnClass === 'load'}
								errors={errors}
								focusHandler={focusHandler}
								name='ccNum'
								label='Card Number'
								value={userInfoData.ccNum}
								textFieldChangeHandler={textFieldChangeHandler}
								InputProps={{
									inputComponent: CCmask
								}}
							/>
						</div>

						<div className='container'>
							<RenderInput
								shouldDisable={submitBtnClass === 'load'}
								errors={errors}
								focusHandler={focusHandler}
								style={{ flex: 3 }}
								name='ccName'
								label='Name on Card'
								value={userInfoData.ccName}
								textFieldChangeHandler={textFieldChangeHandler}
							/>

							<RenderInput
								shouldDisable={submitBtnClass === 'load'}
								errors={errors}
								focusHandler={focusHandler}
								name='ccExp'
								label='Exp'
								value={userInfoData.ccExp}
								textFieldChangeHandler={textFieldChangeHandler}
								InputProps={{
									inputComponent: ExpireMask
								}}
							/>

							<RenderInput
								shouldDisable={submitBtnClass === 'load'}
								errors={errors}
								focusHandler={focusHandler}
								name='cvv2'
								label='CVC'
								value={userInfoData.cvv2}
								textFieldChangeHandler={textFieldChangeHandler}
								InputProps={{
									inputComponent: CvcMask
								}}
							/>
						</div>
					</div>

					<div className='card-container'>
						<Cards
							cvc={userInfoData.cvv2}
							expiry={userInfoData.ccExp}
							focused={userInfoData.focus}
							name={userInfoData.ccName}
							number={userInfoData.ccNum}
						/>

						<span className='credit-bg'></span>
					</div>
				</div>
			</div>

			{props.Children && <props.Children />}

			<div className='submit-section'>
				<div className='bg-logo right'></div>

				<div className='diff-title'>
					<button
						className={`button ${submitBtnClass}`}
						type='submit'
						onClick={submitHandler}
					>
						{!submitBtnClass && 'Pay'}
						{submitBtnClass === 'load' && (
							<CircularProgress size='60px' thickness={3} />
						)}
					</button>
				</div>

				{submitError && (
					<p className='error'>
						There seems to be an error while processing your
						payment. Please try again and make you entered the
						correct info. Or contact us at{' '}
						<a href={constants.PHONECALL}>{constants.PHONE}</a>
					</p>
				)}
			</div>
		</div>
	);
};

export default withRouter(PaymentInfo);

const RenderInput = props => {
	const errorMsg =
		props.errors.input === props.name ? props.errors.message : '';

	return (
		<div className='textfield-container' style={props.style}>
			<TextField
				disabled={props.shouldDisable}
				error={!!errorMsg}
				label={props.label}
				id={props.name}
				value={props.value}
				helperText={errorMsg}
				onChange={e =>
					props.textFieldChangeHandler(props.name, e.target.value)
				}
				InputProps={props.InputProps}
				onFocus={props.focusHandler}
			/>
		</div>
	);
};

function ExpireMask(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			format='##/##'
			mask={['M', 'M', 'Y', 'Y']}
			{...other}
			getInputRef={inputRef}
			onValueChange={values => {
				onChange({
					target: {
						value: values.value
					}
				});
			}}
		/>
	);
}

function CCmask(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			format='####  ####  ####  ####'
			{...other}
			getInputRef={inputRef}
			onValueChange={values => {
				onChange({
					target: {
						value: values.value
					}
				});
			}}
		/>
	);
}

function CvcMask(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			format='####'
			{...other}
			getInputRef={inputRef}
			onValueChange={values => {
				onChange({
					target: {
						value: values.value
					}
				});
			}}
		/>
	);
}
