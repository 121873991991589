import React from 'react';
import MetaTags from 'react-meta-tags';

import './sass/staff.scss';

const Staff = () => {
	return (
		<div className='staff default-width'>
			<MetaTags>
				<title>Staff</title>
				<meta name='description' content='Beit Nesicha Staff' />
				<meta property='og:title' content='Staff' />
			</MetaTags>

			<div className='diff-title'>
				<h3 className='title'>Our Staff</h3>
			</div>

			<div className='bg-logo'></div>
			{staffInfo.map((currentStaff, index) => {
				return (
					<div key={currentStaff.name.split(' ').join('')} className='single-staff-container'>
						{index === 3 && <div className='bg-logo right'></div>}
						<StaffContainer {...currentStaff} />
						{index !== staffInfo.length - 1 && <div className='line-separator'></div>}
					</div>
				);
			})}
		</div>
	);
};

const StaffContainer = (props) => {
	return (
		<div className='staff-single'>
			<div className='title-container'>
				<h3>{props.name}</h3>
				<h4>{props.title}</h4>
				<div className='line'></div>
			</div>
			<div className='description'>
				{props.desc.map((desc, index) => (
					<p key={`description_${index}`}>{desc}</p>
				))}
			</div>
		</div>
	);
};

export default Staff;

const staffInfo = [
	{
		name: 'Matthew (Yosef) Milstein',
		title: 'Clinical Psychologist',
		desc: [
			`Matthew (Yosef) Milstein, PhD. is a clinical psychologist with a long history of working with adolescents and adults struggling with compulsive behaviors, identity issues, emotional instability, and relational conflicts. His primary field experience has been with the dual diagnosis (addictions and co-occurring mental health issues) population. During his 6 years’ employment at Chabad Treatment Center (Los Angeles), Dr. Milstein facilitated individual and group therapy, completed clinical intakes, developed treatment plans, conducted case management, aided in utilization reviews, and performed administrative duties. Dr. Milstein’s therapeutic orientation is person-centered, which he integrates with Acceptance and Commitment Therapy (ACT) and 12-step recovery tools.`,
			`As the program director of Beit Nesicha, Dr. Milstein is responsible for program development and the implementation of effective interventions. He oversees client orientation and progress in every stage of recovery through completing clinical intakes, working with staff RD in treatment plan development, meeting with clients for bimonthly assessments on progress and struggles, facilitating family and group therapy sessions (e.g., process, emotional stability, and implementing healthy coping/communication skills), and leading case conference/staff-training workshops.`,
		],
	},
	// {
	// 	name: 'Yael Roizman',
	// 	title: 'CFO & Program Administrator',
	// 	desc: [
	// 		`Yael Roizman graduated magna cum laude from Stern College for Women with a B.A. in Psychology. While attending college, she volunteered at a battered women’s shelter and was later employed by Ohel Children’s Home in their foster care division. Yael continued her education to become a certified programmer, taking a position at AIG (New York office). She later relocated to Los Angeles, where she took a teaching position at a local school. When she’s not in the classroom enjoying her passion for teaching, Yael likes to spend time with her family, hike, and play tennis.`,
	// 		`As the CFO and program administrator of Beit Nesicha, Yael oversees all program finances, policy-making, communication, and logistics.`,
	// 	],
	// },
	// {
	// 	name: 'Aviva Goodman',
	// 	title: 'Operations Manager',
	// 	desc: [
	// 		`Aviva Goodman has a Masters of Education from Harvard University, where she studied Psychology and Child Advocacy. Her areas of specialty include trauma and how it impacts adolescent development, restorative justice practices, trauma-informed programming, and curriculum development. She moved to Israel in 2011 from New Jersey and enjoys painting, hiking, and climbing trees with her children.`,
	// 		`As operations manager, Aviva oversees the daily functioning of the program including scheduling, attendance, educational/vocational progress, in-house support, supplies, and safety.`,
	// 	],
	// },
	// {
	// 	name: 'Ashira Krakowski',
	// 	title: 'Registered Dietitian',
	// 	desc: [
	// 		`Ashira Krakowski is a registered dietitian (both in the US and Israel). She holds an MSc (Nutrition) from UCLA, and a post-graduate certificate as a specialist in eating disorders from Bar-Ilan University. For the past 10 years, Ashira has been working with young adults who struggling with ED, in Israel’s leading hospitals including Tel HaShomer and Hadassah Medical Center in Jerusalem, and in private practice. She has led projects for the Ministry of Health and various public organizations.`,
	// 		`As the staff RD of Beit Nesicha, Ashira completes nutritional assessments and individualized treatment plans, meets clients bi-weekly to assess progress and struggles, works with the program director and physician to ensure client physical stability, trains in-house staff on helping clients in meal support and post-meal support, and oversees Beit Nesicha meal planning and weekly physical activities.`,
	// 	],
	// },
	// {
	// 	name: 'Yannah Popovitz',
	// 	title: 'Expressive Arts Therapist',
	// 	desc: [
	// 		`Yannah Popovitz, M.A. received her BA from Touro College and her MA from Lesley University in Expressive Arts Therapies. She also has certification (Machon Cheruv) in treating trauma and sexual abuse. Yannah works with a holistic approach using various integrated techniques including tangible art, breathing, mindfulness, and creative writing, while seeing the person as a whole. For the past decade she has been facilitating both individual and group therapy, along with running therapy tool-building workshops for other therapists. Yannah has extensive experience working with individuals struggling with addictions, eating disorders, boundary issues, and the negative effects of developmental trauma. As a result of working with Yannah, these individuals have reported increased self-love and self-awareness, reduced self-destructive behaviors, healing of trauma, and became empowered to take ownership of their lives.`,
	// 	],
	// },
	// {
	// 	name: 'Lisa Ormonde',
	// 	title: 'CBT/DBT Therapist',
	// 	desc: [
	// 		`Lisa Ormonde received her education/accreditation through Queen Mary (University of London) and Royal Holloway (University of London).  For the past 10+ years she has been utilizing cognitive behavioral therapy and dialectical behavioral therapy to help individuals recover from a wide range of mental health issues including: high anxiety/stress, depression, low self esteem, eating disorders and personality disorders.  With her extensive experience and knowledge in the field, Lisa has facilitated training seminars in clinical psychology, and has delivered a number of talks at the Consultants Conference (London) and the National Eating Disorders Conference (London).`,
	// 	],
	// },
	// {
	// 	name: 'Shoshana Schwartz',
	// 	title: 'Therapist',
	// 	desc: [
	// 		`Shoshana Schwartz is a therapeutic horseback riding instructor, addictions counselor, and accredited certified EFT practitioner. Shoshana, Reterno's onsite therapist, combines equine therapy with the twelve-step model of addiction recovery as developed by Rabbi Eitan Eckstein in Retorno. Shoshana has extensive experience working with Jewish women with various cultural backgrounds. She is the author of four books and a frequent contributor to Mishpacha magazine.`,
	// 	],
	// },
	// {
	// 	name: 'Sarah Caras',
	// 	title: 'Yoga & Meditation Instructor',
	// 	desc: [
	// 		`Sarah Caras is a certified yoga and meditation instructor. She made aliyah in 2005 from Teaneck, NJ, and currently lives in Kochav Yaakov with her husband and three yogi children. She was first introduced to the world of yoga, meditation and wellness in 2008 and has been practicing since. In 2016 she received her yoga teaching certification from Kinneret Yoga, a Yoga Alliance registered school. Sarah combines her yoga education with wellness by focusing on slowing down, and connecting with the inner self on a spiritual plane. She believes yoga is for everyone, and customizes her classes for each student's individual needs and skill level, all within a safe, non-judgmental environment. Sarah's goal in teaching yoga is to encourage her students to focus on a mind body connection, which guides each of us on the path of self-restoration and the gift of life.`,
	// 	],
	// },
	// {
	// 	name: 'Batya Ruddell',
	// 	title: 'Creative Writing Instructor',
	// 	desc: [
	// 		`Batya Ruddell is a multi- genre, creative writer, working exclusively for Hamodia and Binah publications, and the author of seven books: The View from Ninveh; Changing Faces; Picture Perfect; Ready to Fly, False Fronts, Invisible Ties and On Their Derech.`,
	// 		`Batya conducts writing workshops for women and teenage girls and also mentors writers privately. In 2017 she received her certification as a Narrative Therapist and opened up a practice. In addition Batya teaches creative writing to girls in several seminaries in Jerusalem, including Jewessence and Rabbi Shimon Russell’s school, Tikva. She particularly enjoys working with girls who are struggling and believes that self-expression through writing is a means to emotional health. The atmosphere in her classes is relaxed, expressive, open and non- judgmental.`,
	// 		`Batya’s newest book, On Their Derech was released in Pesach 2019. It is a groundbreaking work that provides support and understanding for parents of struggling teens. In addition she facilitates support groups for mothers of children who are “On their Derech” and regularly speaks publically on this topic.`,
	// 		`Batya lives in a small community outside Jerusalem with her husband and children, where she can be found - horseback riding in the Judean hills near her home!`,
	// 	],
	// },
	{
		name: 'Other Staff',
		desc: [
			`Beit Nesicha employs 3 in-house counselors who work rotating, overlapping shifts to ensure that our clients have 24-hour support. Our counselors are trained in crisis intervention, first aide, NARCAN administration, and offering meal/post-meal support.`,
			`Twice a week our clients work with the Israel Dog Unit (IDU) where they work directly with a variety of dogs (mainly Belgian Shepherds) and can assist with search/rescue missing persons. Girls who do not want to work with dogs have the opportunity of working with drones and grid mapping for the searches. The IDU has also offered Beit Nesicha to raise a few pups until they are ready to be trained.`,
			`Beit Nesicha connects clients to specialists for individual needs. For example, Dr. Shmuel Harris is our recommended psychiatrist. The client’s physician will be based on which insurance coverage the client has in Israel. For example, clients with AIM will get checkups, bloodwork, and medical assessments by Dr. Eyal (under Dr. Lazer Fruchter). We also have referrals for therapists if the client is in need of individual therapy for a specific issue (e.g., the effects of developmental trauma that prevent the client from stabilizing in our program). We work with these specialists as a combined treatment team in order to ensure client stabilization, progress, and safety.`,
		],
	},
];
