import React from 'react';

import Button from './reusable/Button';
import '../sass/imageHeader.scss';

import { ReactTitle } from 'react-meta-tags';

const ImageHeader = () => {
	const currentPath = window.location.pathname.split('/').pop() || 'home';
	const imgName =
		currentPath === 'home' ? 'home-filled' : 'for-all-pages-filled';

	const bgImageStyle = {
		backgroundImage: `url(/images/headerImages/${imgName}.png)`
	};

	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const HeaderText = title => {
		if (title === 'home') {
			return (
				<div className='home-content header-title'>
					<div className='title'>
						<h3>Serenity is not freedom from the storm,</h3>
						<h3 style={{ marginTop: '15px' }}>
							but peace amid the storm
						</h3>
					</div>
					<Button
						link='/contact'
						text='Get Help Now'
						inverted='true'
					/>
				</div>
			);
		} else {
			let currentTitle = window.location.href.split('/');
			currentTitle = currentTitle[currentTitle.length - 1];

			return (
				<div className='header-title'>
					<div className='filler' />
					<h3
						className='title'
						dangerouslySetInnerHTML={{
							__html: currentTitle.split('-').join(' ')
						}}
					></h3>
				</div>
			);
		}
	};

	let headerClassName = 'image-header';
	if (currentPath === 'home') headerClassName += ' home';
	return (
		<div className={headerClassName}>
			<ReactTitle
				title={`Beit Nesicha - ${capitalizeFirstLetter(
					currentPath.split('-').join(' ')
				)}`}
			/>
			<div className='content-wrapper'>
				<div className='img-overlay' />
				<div className='bg-image' style={bgImageStyle} />
				<div className='content default-width'>
					{HeaderText(currentPath)}
				</div>
			</div>
		</div>
	);
};

export default ImageHeader;
