import axios from 'axios';

// axios.defaults.withCredentials = true;
// axios.defaults.baseURL = process.env.REACT_APP_API_URL;

// axios.defaults.baseURL = 'http://localhost:4000';
axios.defaults.baseURL = 'https://hhfz18okqg.execute-api.us-west-1.amazonaws.com/donate';

axios.interceptors.response.use(null, (error) => {
	const expectedError = error.response && error.response.status >= 400 && error.status < 500;

	if (!expectedError) {
		// console.log(error.response);
	}

	return Promise.reject(error);
});

export default {
	get: axios.get,
	post: axios.post,
	put: axios.put,
	delete: axios.delete,
};
