import React from 'react';
import './quote.scss';

const Quote = props => {
	const fullWidthClassName = props.fullWidth ? 'default-width' :'';
	return (
		<div className="quote-container">
			<h3 className={`${fullWidthClassName}`}>
				{props.content}
			</h3>
		</div>
	);
};

export default Quote;