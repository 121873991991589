import React from 'react';
import Buttton from './Button';

const DonateBtn = props => {
	const text = props.text ? props.text : 'DONATE';
	
	return <Buttton inverted={props.inverted} text={text} link="/donate" />;
};

export default DonateBtn;
