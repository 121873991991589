import React from 'react';

import Button from '../reusable/Button';
import Keypoints from '../reusable/Keypoints';
import './sass/about.scss';
import Tooltip from '@material-ui/core/Tooltip';
import MUIbutton from '@material-ui/core/Button';

import MetaTags from 'react-meta-tags';

const About = () => {
	return (
		<div className='about-page default-width'>
			<MetaTags>
				<title>About Beit Nesicha</title>
				<meta
					name='description'
					content='Beit Nesicha is a dual diagnosis treatment program that aims to restore confidence and self-worth to young struggling Jewish women'
				/>
				<meta property='og:title' content='About Beit Nesicha' />
			</MetaTags>

			{aboutContent.map((content, index) => {
				const shouldAlignLeft = index % 2;
				return (
					<div
						key={content.title}
						className='about-section-container'
					>
						{(index === 0 || index === 4) && (
							<div className='bg-logo'></div>
						)}
						{index === 1 && <Keypoints />}
						{index === 2 && (
							<>
								<div className='diff-title'>
									<h3 className='title'>
										How Beit Nesicha is Different
									</h3>
								</div>
								<div className='bg-logo right'></div>
							</>
						)}

						{index === 7 && <div className='bg-logo right'></div>}

						<AboutSection
							{...content}
							left={shouldAlignLeft}
							// shouldShowLine={index < 2}
						/>
					</div>
				);
			})}

			<div className='ethics-container'>
				<div className='diff-title ethics'>
					<div className='title'>
						<h3>Our Ethics</h3>
					</div>
				</div>

				<div className='bullet-point-section'>
					<BulletPointComponent {...bulletPointContent[0]} />
					<BulletPointComponent {...bulletPointContent[1]} />
				</div>
			</div>
		</div>
	);
};

export default About;

const AboutSection = props => {
	let aboutClass = 'about-section';
	if (props.left) aboutClass += ' left';

	return (
		<div className={aboutClass}>
			<img src={props.image} alt='' />
			<div className='text'>
				<h3 className='title'>{props.title}</h3>
				{props.shouldShowLine && <div className='line'></div>}
				<div className='content'>
					{props.content.map(content => {
						return <p key={content}>{content}</p>;
					})}
					{props.button && (
						<Button
							text={props.button.text}
							link={props.button.link}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const BulletPointComponent = props => {
	return (
		<div className='bullet-point-container'>
			<h4 className='title'>{props.title}</h4>
			<ul className='content'>
				{props.content.map(content => {
					return <li key={content}>{content}</li>;
				})}
			</ul>
		</div>
	);
};

const aboutContent = [
	{
		title: 'About Beit Nesicha',
		content: [
			<div>
				Beit Nesicha is a dual diagnosis treatment program that aims to
				restore confidence and self-worth to young Jewish women (ages 17
				and up) who are struggling with addiction, eating disorders, and
				co-occurring mental health issues (e.g., PTSD, depression, panic
				attacks, OCD, emotional dysregulation). We provide a warm,
				empowering environment for these women seeking to nurture their
				royal spark into a shining beacon of serenity, dignity, and
				courage. Our setting promotes growth from the inside out, where
				one’s past has no bearing on her future, and where honesty and
				self-respect reign.
			</div>,
			<div>
				Our program is located in Kochav Yaakov - a warm community in
				the rural outskirts of Jerusalem. Our location outside of the
				city helps to avoid the noise, rush, and attractions - all
				distractions from recovery. The quiet, open environment promotes
				personal growth, anxiety/stress reduction, self-exploration, and
				clarity.
			</div>,
			<div>
				As a result of working through the Beit Neischa program, Jewish
				women will recover from addiction, eating disorders, and
				co-occurring mental health issues, and start living their lives
			</div>
		],
		// image: '/images/stockImages/scene021.jpg',
		image: '/images/home/interior1.jpg',
		button: {
			link: '/services',
			text: 'Our Services'
		}
	},
	{
		title: 'How we are similar to other programs:',
		content: [
			<div>
				We use treatment planning, evidence-based interventions (e.g.,
				the 12-step model,{' '}
				<Tooltip
					disableFocusListener
					disableTouchListener
					interactive
					title={
						<p className='tooltip-text'>
							Acceptance and commitment therapy (ACT) is an
							action-oriented approach to psychotherapy. Clients
							learn to stop struggling with their inner emotions
							and, instead, accept that these deeper feelings are
							appropriate responses to certain situations that
							should not prevent them from moving forward in their
							lives.
						</p>
					}
				>
					<MUIbutton>ACT</MUIbutton>
				</Tooltip>
				), and mind/body activities (e.g., fitness, meditation), to help
				promote recovery and minimize delinquent/high-risk behaviors. We
				strive to rebuild family relationships and aim to develop a
				healthy support network that aids in relapse prevention.
				Treatment extends beyond therapy (equine, art to name a few) to
				include developing independent living skills and effective
				communication skills through workshops and achieving
				educational/vocational goals (e.g., earning a GED).
			</div>
		],
		image: '/images/stockImages/journal.jpg',
		button: {
			link: '/services',
			text: 'Our Services'
		}
	},
	{
		title: 'Our Uniqueness:',
		content: [
			<div>
				We consider low self-worth to be the key factor in
				addiction/relapse, eating disorders, and co-occurring mental
				health issues. You may constantly struggle with an inner critic
				that relays the message, &quot;You&#39;re not good enough, you
				don&#39;t deserve to have a good life, you don&#39;t deserve to
				feel loved.&quot; The constant presence of this inner critic
				explains why individuals graduate treatment programs, get a good
				job and build relationships with positive individuals, and
				ultimately self-sabotage their successes. Deep down inside, this
				inner critic gives the constant message, &quot;you don&#39;t
				deserve this.&quot; Our program was built with the primary focus
				of weakening this inner critic. We use the{' '}
				<Tooltip
					disableFocusListener
					disableTouchListener
					interactive
					title={
						<p className='tooltip-text'>
							<span className='underline'>A</span>dult{' '}
							<span className='underline'>C</span>hildren of{' '}
							<span className='underline'>A</span>lcoholics and
							Dysfunctional Families (ACA) is a self-help group
							that uses the 12-steps and fellowship to help
							individuals recover from childhood trauma and
							dysfunction.
							<br />
							<a href='/what-is-aca'>Read More</a>
						</p>
					}
				>
					<MUIbutton>ACA</MUIbutton>
				</Tooltip>{' '}
				12-step program, group activities, spiritual growth, and
				therapies to help identify and become mindful of the inner
				critic, in order to challenge and overcome its negative pull
				towards self-sabotage.
			</div>
		],
		image: '/images/stockImages/hike.jpg',
		button: {
			link: '/services',
			text: 'Our Services'
		}
	},
	{
		title: 'Our Process:',
		content: [
			<div>
				We recognize that childhood trauma and lack of support create
				the gateway to addiction, eating disorders, and co-occurring
				mental health issues. Our residential program focuses on
				processing and reducing the effects of trauma in a group
				setting. Moving into Transitional Living, the individual is now
				stable (e.g., withdrawal symptoms no longer present), and has
				the tools to effectively engage in (and get the most out of)
				individual therapy per treatment needs.
			</div>,
			<div>
				Moving beyond the basic goal of maintaining sobriety, Beit
				Nesicha measures progress through bi-weekly mental health
				testing/assessments, treatment plan review, and
				individual/family feedback. We are 100% invested in the success
				of every woman who enters our program. As part of the Beit
				Nesicha family, we continue to stay in touch with our graduates,
				and look forward to sharing and being supportive in all of their
				future occasions (both joyous and sad).
			</div>
		],
		image: '/images/stockImages/support.jpg',
		button: {
			link: '/services',
			text: 'Our Services'
		}
	},
	{
		title: 'How we Operate:',
		content: [
			<div>
				Beit Nesicha is not a penal or boot-camp system, but instead a
				warm, family-oriented program that promotes growth on both an
				individual and group level. We believe that individuals in
				recovery are often too hard on themselves (especially when they
				set unrealistic goals and fall short). Our structured daily
				schedule, interventions, warm and open atmosphere, and staff
				modeling help women in our program to excel in: learning
				independent living skills, becoming assertive for their
				needs/wants, and developing healthy coping skills for daily
				stressors and regulating emotions. As their self-worth builds,
				resentments towards themselves and others will fall away, they
				will start taking ownership of their lives, and they will set
				healthy achievable life goals that promote happiness, love,
				connection, and a sense of belonging.
			</div>,
			<div>
				Throughout the entire program, women will be given their phones
				(or access using program computers) for a few hours at the end
				of the clinical day. We want them to have the option to
				reconnect with family, strengthen their support network, and
				enjoy personal time on social media/internet - within a healthy
				time-frame.
			</div>
		],
		image: '/images/stockImages/scene021.jpg',
		button: {
			link: '/services',
			text: 'Our Services'
		}
	},
	{
		title: 'How We Incorporate Torah Concepts:',
		content: [
			<div>
				Beit Nesicha uses Torah concepts to modify current treatment
				models. Our program uses 40-day increments instead of the
				current 30-day increments seen in other programs.
				Understandably, current programs use 30-day increments to align
				with monthly cycles, and for insurance purposes. The Torah views
				40 days as a block of time for creation or significant change.
			</div>,
			<div>
				Several examples: the Torah was given after 40 days, the neshama
				enters the fetus after 40 days from conception, it takes 40 days
				to change a middah (characteristic/virtue). The residential
				program at Beit Nesicha is divided into 3 x 40-day tiers.
			</div>,
			<div>
				Our program is founded on Torah principles. Therefore, we offer
				Judaism - educational classes, kosher food, and Shabbat/Holidays
				are honored with connection, good food, and song. To clarify, we
				offer Judaism, but it is not forced. This means no one is
				obligated to keep Jewish law. However, we ask that there is
				mutual respect in keeping the kitchen kosher, and wearing
				clothes with self-respect in mind (pants are allowed - self
				respect means not wearing something too revealing or
				distasteful).
			</div>
		],
		image: '/images/stockImages/shabbos.jpg',
		button: {
			link: '/services',
			text: 'Our Services'
		}
	},
	{
		title: 'First We Build:',
		content: [
			<div>
				A fitness trainer once stated that he advised people who were
				seeking to diet, to start with introducing healthy foods, and
				specifically not to stop eating unhealthy foods as this would
				create an immediate desire for those foods. He believed that
				psychologically, we crave things that are unhealthy for us and
				the craving greatly increases when we think we can no longer
				have them. Therefore, a person should keep eating the unhealthy
				food and introduce different greens (e.g., kale, spinach,
				salads, etc.). After a while, the body will naturally want the
				healthy foods and slowly move away from the unhealthy foods.
			</div>,
			<div>
				Similarly, a historic ideal within the Mussar movement has been
				to build (strengthen) the person before giving mussar. The Beit
				Nesicha residential program follows this ideal - first we build.
				The 1st tier focuses on introducing positive thoughts and
				behaviors (connecting within the group, spiritual growth,
				opening up in a safe and welcoming setting). The 2nd tier
				focuses on reducing negative thoughts and behaviors (reducing
				mistrust, dishonesty, self-harm). The 3rd tier focuses on
				stabilizing - maintaining the growth from the previous tiers,
				and refining individual treatment goals.
			</div>
		],
		image: '/images/stockImages/possible.jpg',
		button: {
			link: '/services',
			text: 'Our Services'
		}
	},
	{
		title: 'Spiritual Growth and Connection:',
		content: [
			<div>
				Every weekday morning there will be a trip to Mishkan Shilo
				where women will have time to meditate and pray, followed by a
				process group. Beit Nesicha views Mishkan Shilo as a very
				special place for women as it is a Torah-documented source where
				a Jewish woman connected and had her prayers answered - Chana
				prayed to have a child at Mishkan Shilo and she gave birth to
				the prophet Shmuel. Beit Neischa makes Rosh Chodesh a very
				special time for women through having a festive breakfast and
				visiting the Kotel. We also advise families to buy something
				nice before holidays for their loved ones in treatment.
			</div>
		],
		image: '/images/stockImages/shilo.jpg',
		button: {
			link: '/services',
			text: 'Our Services'
		}
	}
];

const bulletPointContent = [
	{
		title: `While we do not have a penal system, there are 3 reasons for being asked to leave our program:`,
		content: [
			<div>
				Putting your life at risk. For example, overdosing, continued
				heavy drug use, or getting into high-risk/life-threatening
				situations. To clarify, this is a completely sober program. This
				means alcohol and all illicit drugs (e.g., marijuana,
				psychedelics, opiates, amphetamines) are not allowed. We also do
				not allow addictive prescription drugs (e.g., Adderall). At Beit
				Nesicha we do not believe in expelling someone from our program
				for slipping, but instead we intervene and work with the
				individual toward successfully completing our program.
			</div>,
			<div>
				Putting someone else's life at risk. For example, bringing drugs
				or alcohol into the facility.
			</div>,
			<div>
				Putting the program at risk. For example, the program is located
				in a shomer-Shabbat community. While women are allowed to smoke
				on Shabbat (Judaism is offered in our program, not forced), they
				have a designated area for smoking. If someone is seen smoking
				outside of the facility on Shabbat, and community members
				complain, this puts our program at risk.
			</div>
		]
	},
	{
		title: `Who is not a good candidate for our program:`,
		content: [
			<div>
				Someone who requires a higher level of care (i.e.,
				hospitalization) due to medical or psychiatric needs.
			</div>,
			<div>
				Someone with acute health risk requiring a feeding tube, IV, CT
				scans, ECGs, or hospitalization (a higher level of care) due to
				unstable vital signs and/or coexisting medical problems (e.g.,
				diabetes).
			</div>,
			<div>
				Someone who is not really interested in treatment and feels
				"coerced" - we understand that it is common to struggle with
				hope in having a better life, and that treatment works. We
				welcome individuals who are hesitant and skeptical of treatment,
				but there must be some spark of hope or yearning to change their
				lives.
			</div>
		]
	}
];
