import React from 'react';
import definitionsData from '../../content/definitions';

import './definitionsAndStatsComponent.scss';

const DefinitionsComponent = ({ title }) => {
	return (
		<>
			<div className='diff-title default-width'>
				<h3 className='title'>{title || 'Definitions'}</h3>
			</div>
			<div className='collection-container definitions default-width'>
				{definitionsData.map(content => {
					return (
						<a
							href={`/statistics-and-definitions/definition/${content.title
								.toLocaleLowerCase()
								.split(' ')
								.join('-')}`}
							className='collection'
							key={content.title.split(' ').join('')}
						>
							<h4
								dangerouslySetInnerHTML={{
									__html: content.title
								}}
							></h4>
						</a>
					);
				})}
			</div>{' '}
		</>
	);
};

export default DefinitionsComponent;
