import React from 'react';
import Quote from '../reusable/Quote';

import './sass/homeContent.scss';
import MetaTags from 'react-meta-tags';

import Button from '../reusable/Button';
import Keypoints from '../reusable/Keypoints';
import GetHelp from '../reusable/GetHelp';

const HomeContent = () => {
	const quoteContent = `We have taken both science and medical experts’ breakthroughs in mental health issues and applied torah knowledge to increase success.`;

	return (
		<div className='home-page'>
			<MetaTags>
				<title>Beit Nesicha</title>
				<meta
					name='description'
					content='Our program is located in Kochav Yaakov - a warm community in the rural outskirts of Jerusalem.'
				/>
				<meta property='og:title' content='Beit Nesicha' />
			</MetaTags>

			<Specialties />
			<Quote content={quoteContent} fullWidth='true' />
			<About />
			<div className='default-width'>
				<Keypoints showBgLogo={true} />
			</div>
			<GetHelp showTitle={true} />
		</div>
	);
};

const Specialties = () => {
	const specialtyContent = [
		{
			title: 'Specialized Care',
			content:
				'Our expert staff will treat you with warmth and help you to build your inner strength to challenge and overcome your demons. We accept ALL of you.'
		},
		{
			title: 'Just For You',
			content:
				'We work together to create an individualized treatment plan that will help you on your journey toward achieving your dreams. You are unique and special.'
		},
		{
			title: 'A Program That Works',
			content:
				'We mix evidence-based interventions with innovative methods to help you recover from addiction, eating disorders, and co-occurring mental health issues. Your success is not limited.'
		}
	];

	const SingleSpecialty = props => {
		return (
			<div className='single-specialty'>
				<img src='./images/bullet-icon.png' alt='Bullet Point' />

				<div className='content'>
					<h3>{props.title}</h3>

					<p>{props.content}</p>
				</div>
			</div>
		);
	};

	return (
		<div className='specialties default-width'>
			<div className='bg-logo'></div>
			<div className='title'>
				<h4>Join Beit Nesicha today</h4>
				<h3>because You Deserve to Have a Good Life</h3>
			</div>

			<div className='single-specialty-wrapper'>
				{specialtyContent.map((content, index) => {
					return (
						<React.Fragment key={`singSpecialty${index}`}>
							<SingleSpecialty
								title={content.title}
								content={content.content}
							/>

							{index !== specialtyContent.length - 1 && (
								<div className='line' />
							)}
						</React.Fragment>
					);
				})}
			</div>
		</div>
	);
};

const About = () => {
	return (
		<div className='about-content default-width'>
			<img src='/images/home/interior1.jpg' alt='' />
			<div className='text'>
				<div className='title'>
					<h3 className='heading'>About</h3>
					<h4>Learn more about Beit Nesicha</h4>
				</div>
				<div className='content'>
					<p>
						We consider low self-worth to be the key factor in
						addiction/relapse, eating disorders, and co-occurring
						mental health issues. The individual constantly
						struggles with an inner critic that relays the message,
						"You're not good enough, you don't deserve to have a
						good life, you don't deserve to feel loved." The
						constant presence of this inner critic explains why
						individuals graduate treatment programs, get a good job
						and build relationships with positive individuals, and
						ultimately self-sabotage their successes. Deep down
						inside, the inner critic gives the constant message,
						"you don't deserve this." Our program was built with the
						primary focus of weakening this inner critic. We use the{' '}
						<a href='/what-is-aca'>ACA 12-step program</a>, group
						activities, spiritual growth, and therapies to help
						identify and become mindful of the inner critic, in
						order to challenge and overcome its negative pull
						towards self-sabotage.
					</p>
					<Button text='Learn More' link='/about'></Button>
				</div>
			</div>
		</div>
	);
};

export default HomeContent;
