import React from 'react';
import scrollToComponent from 'react-scroll-to-component';

import './sass/acaPage.scss';

import MetaTags from 'react-meta-tags';

const AcaPage = () => {
	let listRef = React.createRef();

	const scrollToList = () => {
		scrollToComponent(listRef.current, {
			offset: -100,
			align: 'top',
			duration: 500,
			ease: 'inOutQuad'
		});
	};

	return (
		<div className='aca-page default-width'>
			<MetaTags>
				<title>Beit Nesicha and ACA</title>
				<meta
					name='description'
					content='Beit Nesicha uses the ACA 12-step program as the primary recovery tool for dysfunctional thoughts and behaviors.'
				/>
				<meta property='og:title' content='Beit Nesicha and ACA' />
			</MetaTags>

			<div className='about-aca'>
				<div className='bg-logo'></div>

				<div className='diff-title'>
					<h3 className='title'>Beit Nesicha and ACA</h3>
				</div>

				<p>
					Beit Nesicha uses the ACA 12-step program as the primary
					recovery tool for dysfunctional thoughts and behaviors. To
					clarify, Beit Nesicha uses the ACA program for treatment,
					but does not represent the ACA program. Other 12-step
					programs (e.g., AA, NA, OA) are secondary to the ACA
					program. ACA is an acronym for Adult Children of Alcoholics
					and Dysfunctional Families. The program was originally
					created for children of alcoholic parents but members
					quickly found that they shared similar thoughts and
					behaviors (
					<button onClick={scrollToList}>
						see the ACA Laundry List
					</button>
					) with others who had traumatic/dysfunctional childhoods due
					to living in homes where chronic mental or physical illness
					was present, or homes with harsh punishment, or there was
					abuse within the community (sexual, physical, or emotional),
					or homes in which expectations were often too high and
					praise was typically tied to an accomplishment rather than
					given freely. Also included are children who experienced the
					tragic death of a family member/friend, and children from
					divorced homes, or in foster care. While this extensive list
					includes a significant amount of people (possibly most of
					the population), we keep it simple and focus on using
					honesty and awareness to identify dysfunctional thinking and
					behaviors that were developed through childhood experiences.
					We are not here to demonize parents and caregivers, as this
					only builds resentments, but to gradually release the burden
					of unexpressed grief in order to move out of the past.
					Success in this program leads to releasing blame and shame,
					toward taking responsibility and becoming proactive in one's
					life (
					<button onClick={scrollToList}>see the ACA Promises</button>
					). If you are interested in finding out out more about ACA
					or finding a meeting please visit{' '}
					<a
						target='_blank'
						rel='noopener noreferrer'
						href='https://adultchildren.org/'
					>
						adultchildren.org
					</a>
				</p>
			</div>

			<div className='bullet-point-list-container' ref={listRef}>
				<BulletPointList {...bulletPointContent[0]} />
				<BulletPointList {...bulletPointContent[1]} />
				<div className='bg-logo right'></div>
			</div>
		</div>
	);
};

export default AcaPage;

const BulletPointList = props => {
	return (
		<div className='bullet-point-list'>
			<h3 className='title'>{props.title}</h3>
			<h4 className='subtitle'>{props.subtitle}</h4>

			<ol>
				{props.content.map((content, index) => {
					return (
						<li key={content}>
							<span>{index + 1}</span>
							{content}
						</li>
					);
				})}
			</ol>
		</div>
	);
};

const bulletPointContent = [
	{
		title: 'Laundry List',
		subtitle: '14 Characteristics of an Adult Child',
		content: [
			`We became isolated and afraid of people and authority figures`,
			`We became approval seekers and lost our identity in the process`,
			`We are frightened by angry people and any personal criticism`,
			`We either become alcoholics, marry them or both, or find another compulsive personality such as a workaholic to fulfill our sick abandonment needs`,
			`We live life from the viewpoint of victims and we are attracted by that weakness in our love and friendship relationships`,
			`We have an overdeveloped sense of responsibility and it is easier for us to be concerned with others rather than ourselves; this enables us not to look too closely at our own faults, etc`,
			`We get guilt feelings when we stand up for ourselves instead of giving in to others`,
			`We became addicted to excitement`,
			`We confuse love and pity and tend to “love” people we can “pity” and “rescue"`,
			`We have “stuffed” our feelings from our traumatic childhoods and have lost the ability to feel or express our feelings because it hurts so much (Denial)`,
			`We judge ourselves harshly and have a very low sense of self-esteem`,
			`We are dependent personalities who are terrified of abandonment and will do anything to hold on to a relationship in order not to experience painful abandonment feelings, which we received from living with sick people who were never there emotionally for us`,
			`Alcoholism is a family disease; and we became para-alcoholics and took on the characteristics of that disease even though we did not pick up the drink`,
			`Para-alcoholics are reactors rather than actors`
		]
	},
	{
		title: 'What ACA Promises',
		content: [
			`We will discover our real identities by loving and accepting ourselves`,
			`Our self-esteem will increase as we give ourselves approval on a daily basis`,
			`Fear of authority figures and the need to “people-please” will leave us`,
			`Our ability to share intimacy will grow inside us`,
			`As we face our abandonment issues, we will be attracted by strengths and become more tolerant of weaknesses`,
			`We will enjoy feeling stable, peaceful, and financially secure`,
			`We will learn how to play and have fun in our lives`,
			`We will choose to love people who can love and be responsible for themselves`,
			`Healthy boundaries and limits will become easier for us to set`,
			`Fears of failure and success will leave us, as we intuitively make healthier choices`,
			`With help from our ACA support group, we will slowly release our dysfunctional behaviors`,
			`Gradually, with our Higher Power’s help, we will learn to expect the best and get it`
		]
	}
];
