import React from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import MUIbutton from '@material-ui/core/Button';
import Button from '../reusable/Button';
import './sass/servicesPage.scss';
import MetaTags from 'react-meta-tags';

const ServicesPage = () => {
	return (
		<div className='services-page default-width'>
			<MetaTags>
				<title>Beit Nesicha Services</title>
				<meta
					name='description'
					content='Beit Nesicha offers Residential Treatment and Transitional Living.'
				/>
				<meta property='og:title' content='Beit Nesicha Services' />
			</MetaTags>

			<ServiceComponent {...serviceContent[0]} />
			<ServiceComponent {...serviceContent[1]} />

			{/* <div className='not-included-container'>
				<div className='diff-title'>
					<h3 className='title'>Not Included</h3>
				</div>

				<div className='not-included-content'>
					<ServicesNotIncludedComponent />
					<ItemsNotIncludedComponent />
				</div>
				<div className='bg-logo right'></div>
			</div> */}

			{/* <EnteringProgramComponent /> */}
		</div>
	);
};

export default ServicesPage;

const ServiceComponent = props => {
	return (
		<div className='service'>
			<div className='image-panel'>
				<div className='img-container'>
					<img src={props.image} alt='' />
					<div className='details-container'>
						<div className='detail'>
							<h4>Length</h4>
							<span>{props.length}</span>
						</div>
						<div className='detail'>
							<h4>Location</h4>
							<span>{props.location}</span>
						</div>
						<div className='detail'>
							<h4>Cost</h4>
							<span>{props.cost}</span>
						</div>
					</div>
				</div>

				<div className='pointers'>
					<h3 className='title'>
						{props.title.split(' ')[0]}{' '}
						<span>{props.title.split(' ')[1]}</span>
					</h3>

					<div className='sub-title'>{props.subTitle}</div>

					<ul>
						{props.content.map((content, index) => {
							return (
								<li key={`bullet${index}`}>
									<span className='bullet-point'></span>
									{content}
								</li>
							);
						})}
					</ul>
				</div>
			</div>

			<div className='bg-logo'></div>

			<div className='content'>
				<div className='side-point'>
					<h4>About</h4>
					{props.sidePoint.map((content, index) => (
						<p key={`side-point-content${index}`}>{content}</p>
					))}
				</div>

				<div className='about-image'>
					<img src={props.aboutImage} alt='' />
				</div>
			</div>
			<Button text='Join Our Program' link='/contact' />
		</div>
	);
};

const ServicesNotIncludedComponent = () => {
	return (
		<div className='not-included'>
			<h3 className='title'>Services We do Not provide</h3>

			<ul>
				<li>
					<span></span>
					<div className='content'>
						<h4>Detox:</h4>
						<p>
							Individuals must detox before entering Beit Nesicha,
							or provide a letter from a board-certified physician
							that they may enter residential treatment in their
							current state.
						</p>
					</div>
				</li>
				<li>
					<span></span>
					<div className='content'>
						<h4>Psychiatric Needs:</h4>
						<p>
							Beit Nesicha does not have a staff psychiatrist, but
							has a referral for a local US/Israel-board certified
							psychiatrist. Please note that Beit Nesicha does not
							allow prescription medications that are addictive or
							can be abused. This includes but is not limited to:
							Opiates, Benzodiazepines/Sedatives,
							Amphetamines/Stimulants, Barbiturates, and Cannabis.
							Beit Nesicha has a safe for storing prescribed
							medications.
						</p>
					</div>
				</li>
				<li>
					<span></span>
					<div className='content'>
						<h4>Medical/Dental Needs:</h4>
						<p>
							Beit Nesicha does not cover medical or dental needs,
							and does not provide medical devices, e.g., CPAP
							breathing machine, wheel chair, etc.
						</p>
					</div>
				</li>
				<li>
					<span></span>
					<div className='content'>
						<h4>Medical insurance:</h4>
						<p>
							All individuals seeking treatment must have medical
							insurance that is recognized in Israel. Local
							medical insurance can be acquired through Meuchedit
							or Harel - please <a href='/contact'>contact us</a>{' '}
							on how to get insurance here in Israel. Please note
							that Beit Nesicha does not currently take medical
							insurance for treatment coverage. Educational - GED
							test, online courses (Beit Nesicha provides tutors)
						</p>
					</div>
				</li>
				<li>
					<span></span>
					<div className='content'>
						<h4>Room and Board Outside of the Facility:</h4>
						<p>
							In the event that someone leaves (or is asked to
							leave) the program, Beit Nesicha does not provide
							stable living quarters outside of our facility.
						</p>
					</div>
				</li>
			</ul>
		</div>
	);
};

const ItemsNotIncludedComponent = () => {
	return (
		<div className='not-included'>
			<h3 className='title'>Items We do Not provide</h3>

			<ul>
				<li>
					<span></span>
					<div className='content'>
						<h4>Toiletries</h4>
					</div>
				</li>
				<li>
					<span></span>
					<div className='content'>
						<h4>Medication</h4>
					</div>
				</li>
				<li>
					<span></span>
					<div className='content'>
						<h4>Clothing</h4>
					</div>
				</li>
				<li>
					<span></span>
					<div className='content'>
						<h4>Cigarettes/Vapes</h4>
					</div>
				</li>
				<li>
					<span></span>
					<div className='content'>
						<h4>Specialized food items</h4>
					</div>
				</li>
				<li>
					<span></span>
					<div className='content'>
						<h4>Airfare</h4>
					</div>
				</li>
			</ul>
		</div>
	);
};

const EnteringProgramComponent = () => {
	return (
		<div className='entering-program'>
			<div className='diff-title'>
				<h3 className='title'>Entering Our program</h3>
			</div>

			<div className='content'>
				<img
					src='https://i.postimg.cc/d1HvhsZK/shutterstock-449213269.jpg'
					alt=''
				/>
				<ul>
					<li>
						<span></span>
						<p>
							The total cost for treatment (residential and
							transitional living over an 8-month period) is
							$30,000. All finances must be set up before entering
							the program. A minimum of $10,000 must be paid
							before coming on site for treatment. We offer an
							option to pay the additional $20,000 in 4 monthly
							payments (cash, check, bank transfer, or credit
							card).
						</p>
					</li>

					<li>
						<span></span>
						<p>
							Billing is based on 40-day increments. In the event
							of leaving the program before completion, the
							current 40-day tier will not be reimbursed, but any
							monies paid for remaining treatment time will be
							returned within 10 business days.
						</p>
					</li>

					<li>
						<span></span>
						<p>
							In the event that a deposit was made but the client
							never entered treatment, there will be a processing
							fee of $150, and the remaining deposit will be
							returned.
						</p>
					</li>

					<li>
						<span></span>
						<p>
							All individuals entering Beit Nesicha must be
							detoxed (or letter from physician), have medical
							insurance (recognized in Israel), and must have a
							stable place (family member or friend) in Israel -
							in the event of leaving the program before
							completion.
						</p>
					</li>
				</ul>
			</div>
		</div>
	);
};

const serviceContent = [
	{
		image: '/images/home/facility copy2.jpg',
		aboutImage: '/images/stockImages/combined1.jpg',
		title: 'Residential Treatment',
		location: 'Israel',
		subTitle: (
			<>
				Defined by{' '}
				<Tooltip
					disableFocusListener
					disableTouchListener
					interactive
					title={
						<p className='tooltip-text'>
							The <span className='underline'>A</span>merican{' '}
							<span className='underline'>S</span>ociety of{' '}
							<span className='underline'>A</span>ddiction{' '}
							<span className='underline'>M</span>edicine (ASAM)
							is an addiction medicine professional society
							representing over 6,000 physicians, clinicians and
							associated professionals with a focus on addiction
							and its treatment.
						</p>
					}
				>
					<MUIbutton>ASAM</MUIbutton>
				</Tooltip>{' '}
				criteria - 24-hour care with trained counselors and co-occurring
				enhanced services
			</>
		),
		cost: '$24,000',
		length: '120 days (4 months)',
		content: [
			<div>Full room and board (mehadrin-kosher kitchen)</div>,
			<div>24-hour support</div>,
			<div>Crisis intervention services</div>,
			<div>
				Evidence-based group interventions (12-step,{' '}
				<Tooltip
					disableFocusListener
					disableTouchListener
					interactive
					title={
						<p className='tooltip-text'>
							<span className='underline'>A</span>cceptance and{' '}
							<span className='underline'>C</span>ommitment{' '}
							<span className='underline'>T</span>herapy (ACT) is
							an action-oriented approach to psychotherapy.
							Clients learn to stop struggling with their inner
							emotions and, instead, accept that these deeper
							feelings are appropriate responses to certain
							situations that should not prevent them from moving
							forward in their lives.
						</p>
					}
				>
					<MUIbutton>ACT</MUIbutton>
				</Tooltip>
				,{' '}
				<Tooltip
					disableFocusListener
					disableTouchListener
					interactive
					title={
						<p className='tooltip-text'>
							<span className='underline'>S</span>
							elf-
							<span className='underline'>M</span>anagement{' '}
							<span className='underline'>a</span>nd{' '}
							<span className='underline'>R</span>ecovery{' '}
							<span className='underline'>T</span>raining (SMART
							Recovery) is a global community of people and
							families working together to resolve addictive
							problems.
						</p>
					}
				>
					<MUIbutton>SMART Recovery</MUIbutton>
				</Tooltip>
				, family therapy, art therapy, and animal-assisted therapies)
			</div>,
			<div>Gardening/Ecotherapy</div>,
			<div>Weekly sessions with registered dietitian</div>,
			<div>GED tutoring</div>,
			<div>College prep</div>,
			<div>Vocational training</div>,
			<div>Communication skills workshops/creative writing</div>,
			<div>
				Mind and body (fitness, meditation, recreational activities)
			</div>,
			<div>Weekly off-site activities (e.g., hiking, beach)</div>,
			<div>Random weekly alcohol/drug testing</div>
		],
		sidePoint: [
			'Animal assisted therapies: 10 sessions of equine therapy with a certified counselor take place on the Retorno (Givat Shemesh) campus. Canine-therapy is provided by the Israel Dog Unit (IDU), located in Tapuach. The IDU trains dogs (mostly Belgian Shepherds) for security and search/rescue missions for missing persons all over Israel. Individuals have opportunities to work directly with the dogs, use technology (drones, grid-communication), or physically join searches for missing persons.'
		]
	},
	{
		image: '/images/home/facility02.jpg',
		aboutImage: '/images/stockImages/combined2.jpg',
		title: 'Transitional Living',
		location: 'Israel',
		subTitle: (
			<>
				A hybrid Sober Living and Intensive Outpatient Program defined
				by{' '}
				<Tooltip
					disableFocusListener
					disableTouchListener
					title={
						<p className='tooltip-text'>
							The <span className='underline'>A</span>merican{' '}
							<span className='underline'>S</span>ociety of{' '}
							<span className='underline'>A</span>ddiction{' '}
							<span className='underline'>M</span>edicine is an
							addiction medicine professional society representing
							over 6,000 physicians, clinicians and associated
							professionals with a focus on addiction and its
							treatment.
						</p>
					}
				>
					<MUIbutton>ASAM</MUIbutton>
				</Tooltip>{' '}
				criteria - 9 or more hours a week for treating multidimensional
				instability
			</>
		),
		cost: '$12,000',
		length: '120 days (4 months)',
		content: [
			<div>Full room and board (mehadrin-kosher kitchen)</div>,
			<div>
				Evidence-based interventions (trauma focused - Somatic
				Experiencing,{' '}
				<Tooltip
					disableFocusListener
					disableTouchListener
					interactive
					title={
						<p className='tooltip-text'>
							<span className='underline'>E</span>ye{' '}
							<span className='underline'>M</span>ovement{' '}
							<span className='underline'>D</span>esensitization
							and <span className='underline'>R</span>eprocessing
							(EDMR) is a psychotherapy that enables people to
							heal from the symptoms and emotional distress that
							are the result of disturbing life experiences
						</p>
					}
				>
					<MUIbutton>EMDR</MUIbutton>
				</Tooltip>
				, and{' '}
				<Tooltip
					disableFocusListener
					disableTouchListener
					interactive
					title={
						<p className='tooltip-text'>
							<span className='underline'>D</span>ialectical{' '}
							<span className='underline'>B</span>ehavior{' '}
							<span className='underline'>T</span>herapy (DBT) is
							a type of cognitive behavioral therapy. Its main
							goals are to teach people how to live in the moment,
							cope healthily with stress, regulate emotions, and
							improve relationships with others. It is also
							sometimes used to treat post-traumatic stress
							disorder.
						</p>
					}
				>
					<MUIbutton>DBT</MUIbutton>
				</Tooltip>
				)
			</div>,
			<div>
				<Tooltip
					disableFocusListener
					disableTouchListener
					interactive
					title={
						<p className='tooltip-text'>
							<span className='underline'>A</span>dult{' '}
							<span className='underline'>C</span>hildren of{' '}
							<span className='underline'>A</span>lcoholics and
							Dysfunctional Families (ACA) is a self-help group
							that uses the 12-steps and fellowship to help
							individuals recover from childhood trauma and
							dysfunction.
							<br />
							<a href='/what-is-aca'>Read More</a>
						</p>
					}
				>
					<MUIbutton>ACA</MUIbutton>
				</Tooltip>{' '}
				fellowship, tutoring/college prep (continued)
			</div>,
			<div>
				Mind and body (fitness, meditation, recreational activities)
			</div>,
			<div>Random alcohol/drug testing</div>
		],
		sidePoint: [
			'Transitional living focuses on individual growth and being of service. Through stabilizing (absence of withdrawal symptoms, learning to open up and trust) and gaining healthy coping skills in the residential program, there is a switch from group therapy to individual therapy. Individuals who graduate the residential program become "big sisters" for newcomers into the residential program. Big sisters oversee ACA 12-step work, and provide guidance/support through modeling and one-on-one meetings throughout the week. Being of service includes helping out in the community (e.g., helping a new mother or someone disabled). Beit Nesicha promotes part-time employment in this stage as this builds confidence, daily structure, and increased finances.',
			'We offer the option to extend an additional 120 days in Israel or US.'
		]
	}
];
