import React from 'react';

import './keypoints.scss';

const Keypoints = props => {
	return (
		<div className='core-value-container'>
			{props.showBgLogo && <div className='bg-logo right'></div>}
			<div className='title'>
				<h2>Our Core Values</h2>
			</div>

			<div className='key-component-container'>
				<KeypointComponent
					icon='./images/icons/heartPlus.png'
					title='Recovery'
					content='We implement effective tools that help women recover from addiction, eating disorders, and co-occurring mental health issues.'
				/>
				<KeypointComponent
					icon='./images/icons/head.png'
					title='Stability'
					content={`We strive to rebuild family relationships towards developing a healthy support network that aids in relapse prevention.`}
				/>
				<KeypointComponent
					icon='./images/icons/handShake.png'
					title='Serenity'
					content='We provide a warm, family oriented program that promotes self-worth, inner peace, and a sense of belonging.'
				/>
			</div>
		</div>
	);
};

export default Keypoints;

const KeypointComponent = props => {
	return (
		<div className='keypoint'>
			<img src={props.icon} alt='' />

			<div className='text'>
				<h3 className='title'>{props.title}</h3>
				<p>{props.content}</p>
			</div>
		</div>
	);
};
