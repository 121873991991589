import React from 'react';
import Navbar from './NavBar';
import '../sass/topHeader.scss';

const TopHeader = () => {
	return (
		<div className="top-header">
			<div className="top-line" />
			<Navbar />
		</div>
	);
};

export default TopHeader;
