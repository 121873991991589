import React, { useEffect, useRef } from 'react';

import './paymentComplete.scss';

const PaymentComplete = props => {
	const { state } = props.location;
	const messageContainerRef = useRef(null);

	useEffect(() => {
		window.scrollTo({
			behavior: 'smooth',
			top: 200
		});
	}, []);

	function thankYouMessage() {
		let message = `Thank you, ${state.name}, for your `;
		if (state.monthly) message += `<span>monthly</span> `;
		message += `${state.type} of <span>${formatPrice(state.price)}</span>`;

		if (state.paymentPlan) {
			message += `.<br />You have been charged <span>${formatPrice(
				state.paymentPlan.todaysPrice
			)}</span> and will be billed <span>${formatPrice(
				state.paymentPlan.monthlyPrice
			)}</span> monthly, over the duration of <span>${
				state.paymentPlan.term
			} month`;
			if (state.paymentPlan.term > 1) message += `s`;
			message += '</span>';
		}
		message += '.';
		return message;
	}

	function formatPrice(price) {
		return (
			'$' +
			parseFloat(price)
				.toFixed(2)
				.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
		);
	}

	return (
		<div
			className='payment-complete default-width'
			ref={messageContainerRef}
		>
			<div className='checkmark'>
				<div className='bg-logo'></div>
				<span>&#10003;</span>
			</div>

			<h2 className='title'>
				Your {state.type} has Processed Successfully
			</h2>

			<div
				className='thanks'
				dangerouslySetInnerHTML={{ __html: thankYouMessage() }}
			></div>

			<p className='content'>An email has been sent to {state.email}.</p>

			<div className='line'></div>

			<div className='go-back'>
				<a href='/'>Click here</a> to return to the home page
			</div>
		</div>
	);
};

export default PaymentComplete;
