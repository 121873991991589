import React from 'react';

import './sass/schedulePage.scss';
import MetaTags from 'react-meta-tags';

const SchedulePage = () => {
	return (
		<div className='schedule-page default-width'>
			<MetaTags>
				<title>Schedule</title>
				<meta name='description' content='Beit Nesicha at Beit Nesicha' />
				<meta property='og:title' content='Schedule' />
			</MetaTags>

			<div className='diff-title'>
				<h3 className='title'>Weekly Schedule</h3>
			</div>

			<div className='schedule-summary-container'>
				<div className='schedule-summary-inner-container'>
					<div className='summary-main-title'>Schedule Summary</div>
					<ul>
						<li>
							<div className='schedule-title'>
								Morning <span className='time'>6:30-12:30</span>
							</div>
							<div className='content'>Group and Individual Therapy</div>
						</li>
						<li>
							<div className='schedule-title'>
								Early Afternoon <span className='time'>1:00-4:00</span>
							</div>
							<div className='content'>ACA 12-Step Work and Fellowship</div>
						</li>
						<li>
							<div className='schedule-title'>
								Late Afternoon <span className='time'>4:00-6:00</span>
							</div>
							<div className='content'>Fitness, Educational/Vocational Goals</div>
						</li>
						<li>
							<div className='schedule-title'>
								Night <span className='time'>6:00-10:00</span>
							</div>
							<div className='content'>Given Phones/Laptops, Relax and Unwind After an Amazing Day</div>
						</li>
					</ul>
				</div>
			</div>

			<div className='table-container'>
				<table>
					<thead>
						<tr>
							<th></th>
							<th>
								<h4>Sunday</h4>
							</th>
							<th>
								<h4>Monday</h4>
							</th>
							<th>
								<h4>Tuesday</h4>
							</th>
							<th>
								<h4>Wednesday</h4>
							</th>
							<th>
								<h4>Thursday</h4>
							</th>
							<th>
								<h4>Friday</h4>
							</th>
							<th>
								<h4>Shabbat</h4>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th>6:30-8:30</th>
							<td>Wake-up, Breakfast, Clean Bedrooms, Prep Snacks For Day</td>
							<td>Wake-up, Breakfast, Clean Bedrooms, Prep Snacks For Day</td>
							<td>Wake-up, Breakfast, Clean Bedrooms, Prep Snacks For Day</td>
							<td>Wake-up, Breakfast, Clean Bedrooms, Prep Snacks For Day</td>
							<td>Wake-up, Breakfast, Clean Bedrooms, Prep Snacks For Day</td>
							<td>Wake-up, Breakfast, Clean Bedrooms, Prep Snacks For Day</td>
						</tr>
						<tr>
							<th>8:30-10:00</th>
							<td>Mishkan Shilo, Pray/Meditate, Process Group</td>
							<td>Mishkan Shilo, Pray/Meditate, Process Group</td>
							<td>Equine Therapy</td>
							<td>Mishkan Shilo, Pray/Meditate, Process Group</td>
							<td>Outdoor Therapy (Hike, Beach, Team-Building Skills)</td>
							<td>Mishkan Shilo, Pray/Meditate, Process Group</td>
							<td>Light Breakfast, Neighborhood Stroll, Option to Pray at Different Services</td>
						</tr>
						<tr>
							<th>10:00-11:30</th>
							<td>Art Group Therapy</td>
							<td>DBT Group Therapy</td>
							<td>
								Equine Therapy <span>(Continued)</span>
							</td>
							<td>Canine Therapy (Israel Dog Unit)</td>
							<td>
								Outdoor Therapy (Hike, Beach, Team-Building Skills) <span>(Continued)</span>
							</td>
							<td>Shabbat Prep</td>
							<td>Festive Meal With Singing</td>
						</tr>
						<tr>
							<th>11:30-12:30</th>
							<td>Individual Therapy</td>
							<td>Individual Therapy</td>
							<td>
								Equine Therapy <span>(Continued)</span>
							</td>
							<td>
								IDU <span>(Continued)</span>
							</td>
							<td>
								ODT <span>(Continued)</span>
							</td>
							<td>
								Shabbat Prep <span>(Continued)</span>
							</td>
							<td></td>
						</tr>
						<tr>
							<th>12:30-1:00</th>
							<td>Lunch</td>
							<td>Lunch</td>
							<td>Lunch</td>
							<td>Lunch</td>
							<td>Lunch</td>
							<td>Lunch</td>
							<td></td>
						</tr>

						<tr>
							<th>1:00-2:00</th>
							<td>Gardening</td>
							<td>Registered Dietitian</td>
							<td>Creative Writing Workshop</td>
							<td>Professional Development</td>
							<td>Registered Dietitian</td>
							<td>ACA Meeting</td>
							<td>Relax, games, reading</td>
						</tr>

						<tr>
							<th>2:00-3:00</th>
							<td>ACA 12-Step Work/Tutoring</td>
							<td>ACA 12-Step Work/Tutoring</td>
							<td>
								Creative Writing Workshop <span>(Continued)</span>
							</td>
							<td>ACA 12- Step Work/Tutoring</td>
							<td>ACA 12- Step Work/Tutoring</td>
							<td>ACA 12-Step Work</td>
							<td>
								Relax, Games, Reading <span>(Continued)</span>
							</td>
						</tr>

						<tr>
							<th>3:00-4:00</th>
							<td>ACA Meeting</td>
							<td>ACA Meeting</td>
							<td>ACA Meeting</td>
							<td>ACA Meeting</td>
							<td>ACA Meeting</td>
							<td>Given Phones, Connect With Family</td>
							<td>Community Speaker</td>
						</tr>

						<tr>
							<th>4:00-4:30</th>
							<td>House Chores </td>
							<td>House Chores</td>
							<td>House Chores</td>
							<td>House Chores</td>
							<td>House Chores</td>
							<td>
								Given Phones, Connect With Family <span>(Continued)</span>
							</td>
							<td>
								Community Speaker <span>(Continued)</span>
							</td>
						</tr>

						<tr>
							<th>4:30-6:00</th>
							<td>Yoga</td>
							<td>Yoga</td>
							<td>Mishkan Shilo, Pray/Meditate, Process Group</td>
							<td>Yoga</td>
							<td>Mishkan Shilo, Pray/Meditate, Process Group</td>
							<td>Finish Preparations</td>
							<td>Neighborhood Stroll</td>
						</tr>

						<tr>
							<th>6:00-7:00</th>
							<td>Shower and Dinner Prep</td>
							<td>Shower and Dinner Prep</td>
							<td>Shower and Dinner Prep</td>
							<td>Shower and Dinner Prep</td>
							<td>Shower and Dinner Prep</td>
							<td>Enjoy Shabbat – Festive Meal With Singing</td>
							<td>Light Festive Meal With Singing</td>
						</tr>

						<tr>
							<th>7:00-8:00</th>
							<td>Dinner, 15-Minute Journaling</td>
							<td>Dinner, 15-Minute Journaling</td>
							<td>Dinner, 15-Minute Journaling</td>
							<td>Dinner, 15-Minute Journaling</td>
							<td>Dinner, 15-Minute Journaling</td>
							<td></td>
							<td></td>
						</tr>

						<tr>
							<th>8:00-10:00</th>
							<td>Given Phones, Relax, Connect With Family or Big Sister</td>
							<td>Given Phones, Relax, Connect With Family or Big Sister</td>
							<td>Given Phones, Relax, Connect With Family or Big Sister</td>
							<td>Given Phones, Relax, Connect With Family or Big Sister</td>
							<td>Given Phones, Relax, Connect With Family or Big Sister</td>
							<td>Neighborhood Stroll After Dinner</td>
							<td>Motzei Shabbat-Malava Malka With Open 12-step Women&#39;s Meeting</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div className='notes'>
				<div className='title'>Schedule Change</div>

				<div className='single-note'>
					Every two weeks there is a 30-minute meeting with the clinical director to review individual treatment plans and goals.
				</div>
				<div className='single-note'>Rosh Chodesh: Visit the Kotel with special lunch and activity.</div>
				<div className='single-note'>Erev Chag and Chag: Similar schedule to Erev Shabbat and Shabbat.</div>
				<div className='single-note'>We provide kosher food and opportunities to observe Judaism, but this is not an obligation.</div>
			</div>
			<div className='disclaimer'>* Please note: Schedule may be subject to change at any time without notice.</div>
		</div>
	);
};

export default SchedulePage;
