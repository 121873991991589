import React from 'react';

import Button from '../reusable/Button';
import GetHelp from '../reusable/GetHelp';
import MetaTags from 'react-meta-tags';

import './sass/whyTreatment.scss';

const WhyTreatment = () => {
	return (
		<div className='why-treatment'>
			<MetaTags>
				<title>Why Treatment</title>
				<meta
					name='description'
					content='We have a slogan in recovery: Pain is the effort required to cling to old ideas and old behaviors. Beit Nesicha welcomes you with acceptance and support.'
				/>
				<meta property='og:title' content='Why Treatment' />
			</MetaTags>

			<div className='do-i-have-addiction default-width'>
				<div className='diff-title'>
					<h3 className='title'>
						Do I have an addiction and/or an eating disorder?
					</h3>
				</div>

				<ImageText />
			</div>

			<div className='diff-title default-width'>
				<h3 className='title'>Do I Need Treatment?</h3>
			</div>
			<div className='bg-logo'></div>

			<div className='content therapy-is-necessary default-width'>
				<p>
					There's a popular statement, "Recovery is not for people who
					need it. It's for people who want it." Treatment programs
					often attribute the high rate of relapse to the external
					motivation of individuals entering treatment. External
					motivators are: family, friends, being court mandated.
					Parents may threaten to cut off their child unless she
					enters treatment. Or they shame their child through
					reiterating the damage the child caused to the family
					through her actions. We have seen over and over that shame
					and guilt do not help people in recovery, but instead,
					individuals need internal motivation. This is the internal
					drive - I'm doing it for myself because I want to have a
					better life. Internal motivation provides the fuel to face
					and conquer your demons.
				</p>
				<Button text='Get Help Today' link='/contact' />
			</div>

			<div className='stick-out'>
				<h4 className='default-width'>
					We have a slogan in recovery: Pain is the effort required to
					cling to old ideas and old behaviors. Beit Nesicha welcomes
					you with acceptance and support. Remember that you are a
					princess, deserving of love and respect. We are here to help
					you nurture your royal spark.
				</h4>
			</div>
			<GetHelp className='default-width' />
		</div>
	);
};

export default WhyTreatment;

const ImageText = () => {
	return (
		<div className='image-text-container'>
			<div className='image-text'>
				<img src='/images/stockImages/path2.jpg' alt='' />
				<div className='content'>
					<p className='text'>
						Do I have an addiction and/or an eating disorder?
						Addiction is identified as a chronic disease, and people
						often do not want to label themselves as an "addict".
						Anosognosia is the clinical term used when a person
						truly believes that there is no problem, and that they
						do not have an eating disorder (which is different than
						when a person knows they have an eating disorder but are
						keeping it secret from others). Instead of labeling
						yourself an addict or that you have an eating disorder,
						just ask yourself, "Are my thoughts and behaviors
						bringing pain and chaos into my life? Do I have a
						problem with alcohol, drugs, food, or behaviors where I
						often lose control? Would I rather use alcohol, drugs,
						binge/restrictive eating, or excessively engage in a
						behavior (e.g., exercise, work) in order to distract
						myself from my painful reality?" Making an honest
						assessment of your choices in life, we ask you, "How's
						that been working out for you?" You may come to realize
						that you don't have solutions for your problems,{' '}
						<span>
							that your solutions{' '}
							<span className='bold'>are</span> your problems
						</span>
						.
					</p>
				</div>
			</div>

			<div className='image-text'>
				<img src='/images/stockImages/beach2.jpg' alt='' />
				<div className='content'>
					<p className='text'>
						Denial is usually understood as the individual denying
						that she has an addiction or a drug/alcohol problem.
						Individuals in active addiction, in the denial stage,
						believe that their lives are manageable. The reality is
						that they are trying to manage their lives through using
						unhealthy coping mechanisms (e.g., drugs, sex, emotional
						eating, and even trying to rescue someone else - which
						is really being used as another distraction from a
						painful reality). There is actually another form of
						denial that is much more evasive and sinister. This is
						denial that there is a program that can actually help
						me. The denial that I can actually have a better life.
						This denial is what prevents someone who admits that
						their life is unmanageable, to seek help and invest in
						their recovery. At Beit Nesicha we believe that this
						denial is fueled by the inner critic that says, "I don't
						deserve to have a better life." But the truth is that{' '}
						<span>
							you <span className='bold'>do</span> deserve to have
							a better life. You deserve to be loved and accepted.
							You deserve to have the support to help you achieve
							your dreams
						</span>
						.
					</p>
					<Button text='Get Help Today' link='/contact' />
				</div>
			</div>
		</div>
	);
};
