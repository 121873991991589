import React from 'react';

import './sass/prepareForStayPage.scss';
import MetaTags from 'react-meta-tags';

const PrepareForStayPage = () => {
	return (
		<div className='preparing-for-your-stay default-width'>
			<div className='bg-logo'></div>
			<div className='first-part'>
				<ItemsComponent />
				<OtherInfoComponent />
			</div>
		</div>
	);
};

export default PrepareForStayPage;

const ItemsComponent = () => {
	return (
		<div className='items-list-container'>
			<MetaTags>
				<title>Prepare for your Stay</title>
				<meta
					name='description'
					content='Preparing for your stay at Beit Nesicha'
				/>
				<meta property='og:title' content='Prepare for your Stay' />
			</MetaTags>

			<h3 className='title'>Please be sure to bring the following:</h3>
			<div className='item-list'>
				<ul>
					<li>
						<span>&#10003;</span>Weekday clothing (comfortable,
						pants are acceptable, nothing that is too revealing or
						distasteful)
					</li>
					<li>
						<span>&#10003;</span>
						Pajamas
					</li>
					<li>
						<span>&#10003;</span>
						Weekday shoes
					</li>
					<li>
						<span>&#10003;</span>
						Undergarments
					</li>
					<li>
						<span>&#10003;</span>
						Nice clothing for Shabbat/Holidays
					</li>
					<li>
						<span>&#10003;</span>
						Hiking boots, wicking/trekking socks
					</li>
					<li>
						<span>&#10003;</span>
						Beachwear
					</li>
				</ul>
				<ul>
					<li>
						<span>&#10003;</span>
						Gym clothing, sneakers
					</li>
					<li>
						<span>&#10003;</span>
						Warm rain jacket
					</li>
					<li>
						<span>&#10003;</span>
						Hygienic items (tweezers, nail clippers, etc.)
					</li>
					<li>
						<span>&#10003;</span>
						Toiletries (feminine hygiene, deodorant, q-tips, shower
						sponge, dental care)
					</li>
					<li>
						<span>&#10003;</span>
						Pictures of family
					</li>
					<li>
						<span>&#10003;</span>
						ACA Big Red Book (hard cover)
					</li>
					<li>
						<span>&#10003;</span>
						12 Steps of Adult Children Steps Workbook (yellow,
						spiral bound)
					</li>
				</ul>
			</div>
		</div>
	);
};

const OtherInfoComponent = () => {
	return (
		<div className='other-info-container'>
			<h4 className='title'>Please note:</h4>
			<ul className='other-info'>
				<li>
					Things/Items/Toiletries you can buy in Israel at a decent
					price - shampoo/conditioner, body wash
				</li>
				<li>
					Our facility provides a washing machine and dryer to use
					free of charge
				</li>
			</ul>
		</div>
	);
};
