import React from 'react';
import '../sass/footer.scss';
import constants from '../constants';

import DonateBtn from './reusable/DonateBtn';
import { FirstMenu, SecondMenu } from './reusable/Menu';

const Footer = () => {
	return (
		<>
			<footer>
				<div className='bg-img' />
				<div className='footer-content default-width'>
					{/* <Menu /> */}
					<About />
					<Support />
					<Contact />
				</div>
				<Menu />
			</footer>

			<div className='under-footer default-width'>
				<div>Copyright 2019 | All Rights Reserved.</div>
				<div>501(c)3 Tax ID: 83-2542608</div>
				<div>
					Website Created by: Steve Cahn{' '}
					<a
						target='_blank'
						href='tel://1-323-513-2819'
						rel='noopener noreferrer'
					>
						(323) 513-2819
					</a>
				</div>
			</div>
		</>
	);
};

const Contact = () => {
	const contactInfo = (imgName, info, href, smallInfo) => {
		return (
			<a className='contact-info' href={href}>
				<img
					src={`/images/icons/${imgName}_r.png`}
					className='icon'
					alt=''
				/>
				<span>{info}</span>
				<div className='secondary-info'>{smallInfo}</div>
			</a>
		);
	};

	return (
		<div className='contact'>
			<a href='home' className='logo-link'>
				<img src='/images/logo.png' alt='Beit Nesicha' />
			</a>
			<div className='screen-xl'>
				<h3 className='title'>Contact</h3>
				<p>For immediate treatment help</p>
				<p>Or for general questions</p>
			</div>

			{contactInfo(
				'phone',
				constants.PHONE,
				constants.PHONECALL,
				constants.PHONE_NUMBERS
			)}
			{contactInfo(
				'mail',
				constants.INFOEMAIL,
				`mailto:${constants.INFOEMAIL}`
			)}
		</div>
	);
};

const Support = () => {
	return (
		<div className='support'>
			<div className='support-title'>
				<h4>Help Support Beit Nesicha</h4>
				<h3>Make your tax deductible donation today</h3>
			</div>

			<DonateBtn inverted='true' />
		</div>
	);
};

const About = () => {
	return (
		<div className='screen-xl'>
			<div className='about'>
				<h3 className='title'>About</h3>

				<div className='info'>
					Beit Nesicha is a warm, family-oriented program that
					promotes growth on both an individual and group level.
				</div>
			</div>
		</div>
	);
};

const Menu = () => {
	return (
		<nav className='screen-xl'>
			<ul>
				{/* <FirstMenu />
				<SecondMenu /> */}
				<li className='menu-item'>
					<a href='/'>Home</a>
				</li>
				<li>
					<a href='/about'>About Us</a>
				</li>
				<li>
					<a href='/our-schedule'>Our Schedule</a>
				</li>
				<li>
					<a href='/what-is-aca'>What is ACA</a>
				</li>
				<li>
					<a href='/who-needs-treatment'>Who Needs Treatment</a>
				</li>
				<li>
					<a href='/statistics-and-definitions'>
						Statistics and Definitions
					</a>
				</li>
				<li className='menu-item'>
					<a href='/services'>Services</a>
				</li>
				{/* <li>
					<a href='/payment'>Payment</a>
				</li> */}
				<li>
					<a href='/preparing-for-your-stay'>
						Preparing for your Stay
					</a>
				</li>
			</ul>
		</nav>
	);
};

export default Footer;
