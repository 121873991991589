const DefinitionsData = [
	{
		title: `Dual Diagnosis`,
		desc: [
			`(Also referred to as co-occurring disorders) is a term for when someone experiences a mental health issue and a substance use disorder simultaneously.`
		]
	},
	{
		title: `Addiction`,
		desc: [
			`Compulsive (loss of control) reward seeking at the expense of relationships, obligations, and physical/emotional well being. An individual can be addicted to: alcohol, a drug/substance (e.g., heroin, cocaine, marijuana), shopping, work, exercise, eating, gambling, debting, sex, pornography, gaming, and social media. Individuals engage in addictive behaviors to escape from their painful realities and emotions. Individuals who struggle with addiction tend to be extremely sensitive to injustice, and lack healthy coping skills to work through daily stressors. The addiction helps to temporarily numb feelings, which can include anxiety, sadness, anger, and even joy. Yes, addicts don't want to feel anything - even positive feelings/emotions. Addiction defined by the American Society of Addiction Medicine: Addiction is a primary, chronic disease of brain reward, motivation, memory and related circuitry. Dysfunction in these circuits leads to characteristic biological, psychological, social and spiritual manifestations. This is reflected in an individual pathologically pursuing reward and/or relief by substance use and other behaviors. Addiction is characterized by inability to consistently abstain, impairment in behavioral control, craving, diminished recognition of significant problems with one’s behaviors and interpersonal relationships, and a dysfunctional emotional response. Like other chronic diseases, addiction often involves cycles of relapse and remission. Without treatment or engagement in recovery activities, addiction is progressive and can result in disability or premature death.`,
			`Addiction is known as "Alcohol/Substance" Use Disorder in the DSM 5. The severity (mild, moderate, severe) is rated according to the number of reported criteria (American Psychiatric Association, 2013):`,
			[
				`Taking the substance in larger amounts or for longer than you're meant to.`,
				`Wanting to cut down or stop using the substance but not managing to.`,
				`Spending a lot of time getting, using, or recovering from use of the substance.`,
				`Cravings and urges to use the substance.`,
				`Not managing to do what you should at work, home, or school because of substance use.`,
				`Continuing to use, even when it causes problems in relationships.`,
				`Giving up important social, occupational, or recreational activities because of substance use.`,
				`Using substances again and again, even when it puts you in danger.`,
				`Continuing to use, even when you know you have a physical or psychological problem that could have been caused or made worse by the substance.`,
				`Needing more of the substance to get the effect you want (tolerance).`,
				`Development of withdrawal symptoms, which can be relieved by taking more of the substance.`
			]
		]
	},
	{
		title: `Alcohol vs drug use&#x2012;abuse vs addiction`,
		desc: [
			`(Inaba & Cohen, 2011): Using a substance responsibly or "as prescribed" is defined as "use". For example, drinking wine at Kiddush or using prescribed pain medication after a surgery. One caveat for prescription: if the individual is seeking a higher prescription dose through falsely reporting a higher level of pain, this is understood as "faking bad" and means that the individual is abusing the medication. Abuse is understood as the irresponsible use of a substance or behavior. For example drinking and driving is considered alcohol abuse. Another example, using Adderall to study for a test is abuse. Addiction is understood as the loss of control "powerlessness" in using a substance, alcohol, or behavior. This means that once the individual uses the substance or takes the first drink of alcohol, he/she cannot stop - usually until complete intoxication. People think that if they do not use a substance daily then they are not addicted. This is a mistake because addiction doesn't have to be daily, but can take the form of binge behavior - for example excessive drinking over the weekend, Shabbat/Holidays, but not during the week.`
		]
	},
	{
		title: `Self-medication theory`,
		desc: [
			`Posits that people constantly seek a state of serenity, and will therefore use "medication" to reduce negative emotions/feelings. For example, individuals will use pain killers (opiates) to cope with emotional pain, or will use Benzodiazepines (e.g., Xanax, Klonopin) to cope with anxiety and stress. Continued use of a substance (or any escape behaviors) builds tolerance, leading to dependency. Stopping to use the substance or stopping the behavior brings on withdrawal symptoms (e.g., shaking, feeling on edge, flu-like symptoms, nausea, insomnia) which is the primary reason for relapse because individuals cannot cope with the pain of withdrawals.`
		]
	},
	{
		title: `Dependency`,
		desc: [
			`The need to use a substance or behavior to reduce withdrawal symptoms and feel "normal". For example, an alcoholic will drink to stop his/her hands from shaking. People who stop smoking feel extremely on edge and crave a cigarette to calm down.`
		]
	},
	{
		title: `Tolerance`,
		desc: [
			`Increased use of the substance or behavior is needed for the same medicating/numbing effect.`
		]
	},
	{
		title: `Withdrawal symptoms`,
		desc: [
			`(Post-acute withdrawal symptoms known as PAWS): Symptoms that present when active addiction is stopped. The strength and duration of PAWS depends on the type of substance, dosage level, and length of time used. Withdrawal symptoms usually include: insomnia, feeling on-edge, headaches, shakes, chills, cloudy thinking, flu-like symptoms, vivid dreams, nausea, and strong cravings.`
		]
	}
];

export default DefinitionsData;
