import React from 'react';
import { useParams } from 'react-router-dom';

import DefinitionsComponent from '../reusable/DefinitionsComponent';
import definitionsData from '../../content/definitions';

import './sass/definitionsIndividualPage.scss';

const DefinitionsIndividualPage = () => {
	// const { title } = useParams();
	let title = window.location.href.split('/');
	title = title[title.length - 1];

	let content = definitionsData.filter(
		data =>
			data.title
				.toLocaleLowerCase()
				.split(' ')
				.join('-') === title
	);
	content = content[0];

	return (
		<div className='definitions-individual-page default-width'>
			<div className='bg-logo'></div>

			<div className='diff-title'>
				<h3 className='title'>Definition</h3>
			</div>

			<div className='definition-container'>
				<h3
					className='title'
					dangerouslySetInnerHTML={{ __html: content.title }}
				></h3>
				<div className='description-container'>
					{content.desc.map((desc, index) => {
						return (
							<div key={`desc${index}`}>
								{typeof desc === 'string' && <p>{desc}</p>}

								{typeof desc === 'object' && (
									<ol>
										{desc.map((item, i) => (
											<li key={item.split(' ').join('')}>
												<span>{i + 1}.</span>
												{item}
											</li>
										))}
									</ol>
								)}
							</div>
						);
					})}
				</div>
			</div>

			<DefinitionsComponent title={'Other Definitions'} />
		</div>
	);
};

export default DefinitionsIndividualPage;
