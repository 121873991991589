import React, { useState, useRef } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import constants from '../../constants';
import http from '../../http';
import axios from 'axios';
import Joi from 'joi-browser';
import scrollToComponent from 'react-scroll-to-component';

import MetaTags from 'react-meta-tags';

import './sass/contact.scss';

const Contact = () => {
	return (
		<div className='contact-page'>
			<MetaTags>
				<title>Contact Beit Nesicha</title>
				<meta name='description' content='Whether you have a question about our program, treatment plan, or anything else, we would love to help and answer your questions.' />
				<meta property='og:title' content='Contact Beit Nesicha' />
			</MetaTags>

			<h1 className='title'>Get in Touch</h1>
			<div className='contact-inner-wrapper default-width'>
				<FormComponent />
				<ContactInfo />
			</div>
		</div>
	);
};

const ContactInfo = () => {
	const infoContainer = (imgName, info, link, smallInfo) => {
		return (
			<a href={link} className='single-contact-wrapper'>
				<div className='icon-wrapper'>
					<img src={`./images/icons/${imgName}_r.png`} className='icon' alt='icon' />
				</div>
				<span href={link} className='info'>
					{info}
				</span>
				{smallInfo && (
					<span href={link} className='secondary'>
						{smallInfo}
					</span>
				)}
			</a>
		);
	};

	return (
		<div className='content-info-container'>
			<h3 className='title'>Contact Information</h3>
			<div className='contact-info'>
				{infoContainer('phone', constants.PHONE, constants.PHONECALL, constants.PHONE_NUMBERS)}
				{infoContainer('mail', constants.INFOEMAIL, `mailto:${constants.INFOEMAIL}`)}
			</div>
		</div>
	);
};

const FormComponent = () => {
	const [inputData, setInputData] = useState({
		name: '',
		email: '',
		subject: '',
		message: '',
	});
	const [textFieldError, setTextFieldError] = useState({});
	const [submitValue, setSubmitValue] = useState('Send');
	const [submitMessage, setSubmitMessage] = useState();
	const inputContainerRef = useRef(null);

	const schema = {
		name: Joi.string().required().label('Name'),
		subject: Joi.string().label('Subject'),
		email: Joi.string().email().required().label('Email'),
		message: Joi.string().required().label('Message'),
	};

	const inputChangeHandler = ({ target }) => {
		const newInputData = { ...inputData };
		newInputData[target.name] = target.value;
		setInputData(newInputData);
		setTextFieldError({});
	};

	const submitHandler = async e => {
		e.preventDefault();

		const { error } = Joi.validate(inputData, schema);
		if (error) {
			const errorObj = {
				input: error.details[0].path[0],
				message: error.details[0].message,
			};
			const _textFieldErrors = { ...textFieldError };
			_textFieldErrors[errorObj.input] = errorObj.message;
			setTextFieldError(_textFieldErrors);
			scrollToComponent(inputContainerRef.current, {
				offset: -140,
				align: 'top',
				duration: 500,
				ease: 'inOutQuad',
			});

			return;
		}

		try {
			setSubmitMessage();
			// await http.post('/api/email', inputData);

			const bodyFormData = new FormData();
			bodyFormData.set('name', inputData.name);
			bodyFormData.set('hush_sender', inputData.email);
			bodyFormData.set('subject', inputData.subject);
			bodyFormData.set('message', inputData.message);

			const response = await axios({
				method: 'post',
				url: `https://hushforms.com/infobeitnesicha`,
				data: bodyFormData,
				config: {
					headers: {
						'Content-Type': 'multipart/form-data',
						enctype: 'multipart/form-data',
					},
				},
			});
			console.log(response);

			setInputData({ name: '', email: '', subject: '', message: '' });
			setSubmitMessage(`Your message has been sent to us and we will get back to you within 2 business days.`);
		} catch (error) {
			setSubmitValue('Send');
			setSubmitMessage(`There was an error sending your message. Please try again later, or send us an email at ${constants.INFOEMAIL}`);
		}
	};

	return (
		<div className='contact-form'>
			<p className='intro-text'>
				Whether you have a question about our program, treatment plan, or anything else, we&#39;d love to help and answer your questions. Email us at{' '}
				<a style={{ color: 'inherit', textDecoration: 'underline' }} href={constants.INFOEMAIL}>
					{constants.INFOEMAIL}
				</a>
				. <span style={{ fontSize: 11 }}>Please note: This is a non HIPAA compliant email.</span>
			</p>
			{/* <form onSubmit={submitHandler}> */}
			{/* <form method='post' action='https://hushforms.com/infobeitnesicha' enctype='multipart/form-data'>
				<div className='top-input-wrapper' ref={inputContainerRef}>
					<div className='input-container'>
						<input
							type='text'
							placeholder='Name'
							name='name'
							value={inputData.name}
							onChange={inputChangeHandler}
						/>
						<p className='txt-error'>{textFieldError.name && textFieldError.name}</p>
					</div>

					<div className='input-container'>
						<input
							type='text'
							placeholder='Subject'
							name='subject'
							value={inputData.subject}
							onChange={inputChangeHandler}
						/>
						<p className='txt-error'>{textFieldError.subject && textFieldError.subject}</p>
					</div>

					<div className='input-container'>
						<input
							type='email'
							placeholder='Email'
							name='email'
							value={inputData.email}
							onChange={inputChangeHandler}
						/>
						<p className='txt-error'>{textFieldError.email && textFieldError.email}</p>
					</div>
				</div>
				<div className='input-container'>
					<textarea
						placeholder='Message'
						name='message'
						rows='10'
						value={inputData.message}
						onChange={inputChangeHandler}
					/>
					<p className='txt-error'>{textFieldError.message && textFieldError.message}</p>
				</div>

				<div className='submit-container'>
					<input
						onSubmit={submitHandler}
						type='submit'
						className={`send-btn ${submitValue}`}
						value={submitValue}
					/>
					{submitValue !== 'Send' && <CircularProgress size='60px' thickness={3} />}

					{submitMessage && <p className='submit-msg'>{submitMessage}</p>}
				</div>
			</form> */}
		</div>
	);
};

export default Contact;
