import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import './App.scss';
import './sass/removeDefault.scss';
import './sass/common.scss';

import TopHeader from './components/TopHeader';
import ImageHeader from './components/ImageHeader';

import HomeContent from './components/Pages/HomeContent';
import About from './components/Pages/About';
import AcaPage from './components/Pages/AcaPage';
import Staff from './components/Pages/Staff';
import StaffPage from './components/Pages/StaffPage';
import SchedulePage from './components/Pages/SchedulePage';
import ServicesPage from './components/Pages/ServicesPage';
import PrepareForStayPage from './components/Pages/PrepareForStayPage';
import WhyTreatment from './components/Pages/WhyTreatment';
import Payment from './components/Pages/Payment';
import StatsPage from './components/Pages/StatsPage';
import StatsAndDefinitions from './components/Pages/StatsAndDefinitions';
import StatsIndividualPage from './components/Pages/StatsIndividualPage';
import DefinitionsIndividualPage from './components/Pages/DefinitionsIndividualPage';
import InformationTemplate from './components/Pages/InformationTemplate';
import Contact from './components/Pages/Contact';
import DonatePage from './components/Pages/DonatePage';

import PaymentComplete from './components/reusable/PaymentComplete';

import Footer from './components/Footer';

class App extends Component {
	render() {
		return (
			<div className='App'>
				<TopHeader />
				<ImageHeader />

				<Switch>
					<Route
						path='/info/:content'
						component={InformationTemplate}
					/>
					<Route path='/about' component={About} />
					<Route path='/what-is-aca' component={AcaPage} />
					{/* <Route path='/our-staff' component={StaffPage} /> */}
					<Route path='/staff' component={Staff} />
					<Route path='/our-schedule' component={SchedulePage} />
					<Route path='/services' component={ServicesPage} />
					<Route
						path='/preparing-for-your-stay'
						component={PrepareForStayPage}
					/>
					<Route path='/statistics' component={StatsPage} />
					<Route
						path='/statistics-and-definitions/stats/:title'
						component={StatsIndividualPage}
					/>
					<Route
						path='/statistics-and-definitions/definition/:title'
						component={DefinitionsIndividualPage}
					/>
					<Route
						path='/statistics-and-definitions'
						component={StatsAndDefinitions}
					/>

					<Route
						path='/who-needs-treatment'
						component={WhyTreatment}
					/>
					<Route path='/contact' component={Contact} />
					<Route path='/payment' component={Payment} />
					<Route path='/donate' component={DonatePage} />
					<Route
						path='/payment-complete'
						component={PaymentComplete}
					/>
					<Route path='/' component={HomeContent} />
				</Switch>

				<Footer />
			</div>
		);
	}
}

export default App;
