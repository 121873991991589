import React, { Component } from 'react';
import scrollToComponent from 'react-scroll-to-component';
import ContentText from '../../content/contentText';

import './sass/informationTemplate.scss';

class InformationTemplate extends Component {
	constructor(props) {
		super(props);

		this.sections = {};
		this.currentPath = props.match.params.content;

		this.currentContent = ContentText[this.currentPath];
	}

	setRef = (title, section) => {
		this.sections[title] = section;
	};

	contentComponent = singleContent => {
		let currentComponent;
		if (singleContent.title) {
			currentComponent = (
				<ContentComponent
					setRef={this.setRef}
					title={singleContent.title}
					contents={singleContent.content}
				/>
			);
		} else {
			currentComponent = (
				<ImageComponent imgPath={singleContent.imgPath} />
			);
		}
		return currentComponent;
	};

	render() {
		const tableOfContentsTitle =
			this.currentPath === 'about' ? 'Beit Nesicha' : this.currentPath;

		return (
			<div className="information-template default-width">
				<div className="information-wrapper">
					{this.currentContent.map((singleContent, index) => {
						return (
							<div key={`content${index}`}>
								{this.contentComponent(singleContent)}
							</div>
						);
					})}
				</div>

				<TableOfContentsComponents
					sections={this.sections}
					currentContent={this.currentContent}
					title={tableOfContentsTitle}
				/>
			</div>
		);
	}
}

export default InformationTemplate;

const ContentComponent = ({ title, contents, setRef }) => {
	return (
		<div
			className="information"
			ref={section => {
				setRef(title, section);
			}}
		>
			<h3 className="title">{title}</h3>
			<div className="line" />
			<div className="content">
				<img
					className="quote-img"
					src="/images/quote.png"
					alt="quote"
				/>
				{contents.map(content => {
					return <p key={content}>{content}</p>;
				})}
			</div>
		</div>
	);
};

const ImageComponent = props => {
	return <img className="img" src={props.imgPath} alt="Addiction" />;
};

const TableOfContentsComponents = ({ sections, currentContent, title }) => {
	const buttonList = title => {
		return (
			<li key={title}>
				<button
					onClick={() =>
						scrollToComponent(sections[title], {
							offset: -20,
							align: 'top',
							duration: 500,
							ease: 'inOutQuad'
						})
					}
				>
					{title}
				</button>
			</li>
		);
	};

	return (
		<div className="table-of-contents-wrapper screen-xl">
			<div className={`toc-container`}>
				<h4 className="title">{`Learn About ${title}`}</h4>
				<ul className="toc">
					{currentContent.map(({ title }) => {
						return title && buttonList(title);
					})}
				</ul>
			</div>
		</div>
	);
};
